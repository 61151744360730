<main class="page-content w-ba">
  <section class="select-tab-section">
    <gz-game-select-tab [tabType]="tabType" [casinoBetEnabled]="casinoBetEnabled" [slotBetEnabled]="slotBetEnabled"
      [powerBallBetEnabled]="powerBallBetEnabled" (tabTypeChange)="onTabTypeChange($event)"></gz-game-select-tab>
  </section>
  <section class="provider-section">
    <div class="container">
      <div *ngIf="casinoBetEnabled" [ngClass]="sectionClass('CASINO')">
        <div class="sc-flex">
          <a *ngFor="let casino of casinoProviderList; let i = index" [ngClass]="casinoBtnClass(casino.providerId)"
            [ngStyle]="animationDelay(i)" (mouseenter)="onCasinoProviderMouseEnter(casino.providerId)"
            (mouseleave)="onCasinoProviderMouseLeave()">
            <div class="g-panel w-ba">
              <div class="g-cont">
                <img class="g-img" [src]="getCasinoProviderImage(casino)">
                <button type="button" class="play-btn btn-yellow" (click)="onClickCasinoProvider(casino)">
                  {{'game-enter'|translate}}
                </button>
              </div>
              <div class="g-footer w-ba">
                <div class="g-logo dflex-ac-jc w-ba">
                  <img class="icon-img" [src]="getCasinoProviderLogo(casino)">
                </div>
              </div>
              <div class="g-info">
                <span class="g-name">{{casino.providerCode | translate}}</span>
                <span class="en-text">{{casino.providerCode}}</span>
              </div>
              <div class="glass"></div>
            </div>
          </a>
        </div>
      </div>
      <div *ngIf="slotBetEnabled" [ngClass]="sectionClass('SLOT')">
        <div class="sc-flex">
          <a *ngFor="let slot of slotProviderList; let i = index" [ngClass]="slotBtnClass(slot.providerId)"
            [ngStyle]="animationDelay(i)" (mouseenter)="onSlotProviderMouseEnter(slot.providerId)"
            (mouseleave)="onSlotProviderMouseLeave()">
            <div class="g-panel w-ba">
              <div class="g-cont">
                <img class="g-img" [src]="getSlotProviderImage(slot)">
                <button type="button" class="play-btn btn-yellow" (click)="onClickSlotProvider(slot)">
                  {{'game-enter'|translate}}
                </button>
              </div>
              <div class="g-footer w-ba">
                <div class="g-logo dflex-ac-jc w-ba">
                  <img class="icon-img" [src]="getSlotProviderLogo(slot)">
                </div>
              </div>
              <div class="g-info">
                <span class="g-name">{{slot.providerCode | translate}}</span>
                <span class="en-text">{{slot.providerCode}}</span>
              </div>
              <div class="glass"></div>
            </div>
          </a>
        </div>
      </div>
      <div *ngIf="powerBallBetEnabled" [ngClass]="sectionClass('MINI_GAME')">
        <div class="sc-flex">
          <a *ngFor="let gameGroup of gameGroupList; let i = index" class="sc-btn w-ba"
            [ngClass]="gameGroupBtnClass(gameGroup.id)" [ngStyle]="animationDelay(i)"
            (mouseenter)="onGameMouseEnter(gameGroup.id)" (mouseleave)="onGameMouseLeave()">
            <div class="g-panel w-ba">
              <div class="g-cont">
                <img class="g-img" [src]="gameGroup.thumbnail">
                <button type="button" class="play-btn btn-yellow" (click)="onClickMiniGame(gameGroup)">
                  {{'game-enter'|translate}}
                </button>
              </div>
              <div class="g-footer w-ba">
                <div class="g-logo dflex-ac-jc w-ba">
                  <img class="icon-img" [src]="getPowerBallLogo(gameGroup)">
                </div>
              </div>
              <div class="g-info">
                <span class="g-name">{{gameGroup.name}}</span>
                <span class="en-text">{{gameGroup.name}}</span>
              </div>
              <div class="glass"></div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</main>