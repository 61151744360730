<gz-layout [maxWidth]="1200">
  <div class="inquiry-notice-wrapper inquiry-frame">
    <button nz-button nzType="primary" style="border-radius: 5px;" (click)="createInquiry()">
      {{'create-inquiry'|translate}}
    </button>
    <button nz-button nzType="primary" style="border-radius: 5px; margin-left: 20px;" (click)="deleteAllInquiry()"
      nzDanger>
      {{'delete-all'|translate}}
    </button>
  </div>
  <div class="inquiry-frame">
    <nz-table nzShowSizeChanger [nzData]="inquiryLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
      [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount" (nzQueryParams)="onQueryParamsChange($event)"
      nzBordered nzOutBordered>
      <thead>
        <tr>
          <th>
            {{'title'|translate}}
          </th>
          <th>
            {{'inquiry-date'|translate}}
          </th>
          <th>
            {{'state'|translate}}
          </th>
          <th>
            {{'reply-date'|translate}}
          </th>
          <th>
            {{'watch'|translate}}
          </th>
          <th>
            {{'delete'|translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of inquiryLists">
          <td nzBreakWord>{{data.title}}</td>
          <td nzBreakWord>{{commonService.formatDateTime(data.createDate)}}</td>
          <td nzBreakWord>{{inquiryService.inquiryStateToStr(data.state)}}</td>
          <td nzBreakWord>{{replyDate(data)}}</td>
          <td nzBreakWord>
            <a nz-button nzType="link" (click)="detailInquiry(data.id)">
              {{'watch'|translate}}
            </a>
          </td>
          <td nzBreakWord>
            <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="{{'delete-confirm'|translate}}"
              nzOkText="{{'confirm'|translate}}" nzCancelText="{{'cancel'|translate}}"
              (nzOnConfirm)="deleteRow(data.id)" nzDanger>
              삭제
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</gz-layout>