import { Injectable } from '@angular/core';

import { Casino, ModalMessage, Notice, NoticeType } from '@models';
import {
  ChargeComponent,
  InfoComponent,
  InquiryComponent,
  NoteComponent,
  NoticeComponent,
  PointTransferComponent,
  RefundComponent,
  WholeBettingLogComponent,
} from '@components';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { CasinoComponent } from 'src/pages/home/casino';
import { TranslateService } from 'src/services/translate';

@Injectable()
export class ModalService {
  private errorSrc = new Subject<ModalMessage>();
  error$ = this.errorSrc.asObservable();

  private modalSrc = new Subject<Notice>();
  popup$ = this.modalSrc.asObservable();

  constructor(private modalService: NzModalService, private translateService: TranslateService) {}

  info = (title: string, content: string, isPartner?: boolean) => {
    this.showMessage(title, content, isPartner);
  };

  error = (title: string, content: string, isPartner?: boolean) => {
    this.showMessage(title, content, isPartner);
  };

  warning = (title: string, content: string, isPartner?: boolean) => {
    this.showMessage(title, content, isPartner);
  };

  success = (title: string, content: string, isPartner?: boolean) => {
    this.showMessage(title, content, isPartner);
  };

  showMessage = (title: string, content: string, isPartner?: boolean) => {
    this.errorSrc.next({
      title,
      content,
      className: isPartner ? 'partner-vertical-center-modal' : 'vertical-center-modal',
    });
  };

  noButtonModal = (title: string, content: string) => {
    const modal = this.modalService.create({
      nzIconType: 'success',
      nzTitle: title,
      nzContent: content,
      nzWrapClassName: 'vertical-center-modal',
      nzBodyStyle: {
        fontSize: '16px',
        padding: '20px',
      },
      nzFooter: null,
      nzAutofocus: 'ok',
    });
    return modal;
  };

  confirm = async (title: string, content: string, okLabel?: string) => {
    return new Promise((resolve, reject) => {
      const modal = this.modalService.create({
        nzTitle: title,
        nzContent: content,
        nzWrapClassName: 'vertical-center-modal',
        nzBodyStyle: {
          fontSize: '16px',
          padding: '20px',
        },
        nzFooter: [
          {
            label: this.translateService.translate('cancel'),
            type: 'danger',
            size: 'large',
            onClick: () => {
              modal.destroy();
              reject();
            },
          },
          {
            label: okLabel || this.translateService.translate('confirm'),
            type: 'primary',
            size: 'large',
            onClick: () => {
              modal.destroy();
              resolve();
            },
          },
        ],
        nzAutofocus: null,
      });
      modal.afterOpen.subscribe(() => {
        const element = modal.getElement();
        const footer = element.getElementsByClassName('ant-modal-footer')[0];
        const buttons = footer.getElementsByTagName('button');
        const okBtn = buttons[1];
        okBtn.focus();
      });
      modal.afterClose.subscribe(() => {
        modal.destroy();
        reject();
      });
    });
  };

  partnerConfirm = async (title: string, content: string, okLabel?: string) => {
    return new Promise((resolve, reject) => {
      const modal = this.modalService.create({
        nzTitle: title,
        nzContent: content,
        nzWrapClassName: 'partner-vertical-center-modal',
        nzBodyStyle: {
          fontSize: '16px',
          padding: '20px',
        },
        nzFooter: [
          {
            label: this.translateService.translate('cancel'),
            type: 'danger',
            size: 'large',
            onClick: () => {
              modal.destroy();
              reject();
            },
          },
          {
            label: okLabel || this.translateService.translate('confirm'),
            type: 'primary',
            size: 'large',
            onClick: () => {
              modal.destroy();
              resolve();
            },
          },
        ],
        nzAutofocus: null,
      });
      modal.afterOpen.subscribe(() => {
        const element = modal.getElement();
        const footer = element.getElementsByClassName('ant-modal-footer')[0];
        const buttons = footer.getElementsByTagName('button');
        const okBtn = buttons[1];
        okBtn.focus();
      });
      modal.afterClose.subscribe(() => {
        modal.destroy();
        reject();
      });
    });
  };

  create = (title: string, component: any, width?: number, params?: any) => {
    return this.modalService.create({
      nzTitle: title,
      nzContent: component,
      nzWrapClassName: 'custom-vertical-center-modal',
      nzFooter: null,
      nzWidth: width ? width : 1000,
      nzComponentParams: params,
    });
  };

  createPartnerModal = (title: string, component: any, width?: number, params?: any) => {
    return this.modalService.create({
      nzTitle: title,
      nzContent: component,
      nzWrapClassName: 'partner-vertical-center-modal',
      nzFooter: null,
      nzWidth: width ? width : 1000,
      nzComponentParams: params,
    });
  };

  noticePopUp = (notice: Notice) => {
    if (notice.noticeType !== NoticeType.POPUP) {
      return;
    }
    this.modalSrc.next(notice);
  };

  deleteConfirm = async (title: string, content: string) => {
    return new Promise((resolve, reject) => {
      const modal = this.modalService.confirm({
        nzTitle: title,
        nzContent: content,
        nzOkText: this.translateService.translate('confirm'),
        nzOkType: 'danger',
        nzOnOk: () => resolve(),
        nzCancelText: this.translateService.translate('cancel'),
        nzOnCancel: () => reject(),
        nzWrapClassName: 'vertical-center-modal',
        nzBodyStyle: {
          fontSize: '16px',
          padding: '20px',
        },
        nzFooter: [
          {
            label: this.translateService.translate('cancel'),
            type: 'danger',
            size: 'large',
            onClick: () => {
              modal.destroy();
              reject();
            },
          },
          {
            label: this.translateService.translate('confirm'),
            type: 'primary',
            size: 'large',
            onClick: () => {
              modal.destroy();
              resolve();
            },
          },
        ],
        nzAutofocus: null,
      });
      modal.afterClose.subscribe(() => {
        modal.destroy();
        reject();
      });
    });
  };

  openChargePage = () => {
    this.create(this.translateService.translate('charge-request'), ChargeComponent);
  };

  openRefundPage = () => {
    this.create(this.translateService.translate('refund-request'), RefundComponent);
  };

  openBettingLogPage = () => {
    this.create(this.translateService.translate('betting-log'), WholeBettingLogComponent);
  };

  openNoticePage = () => {
    this.create(this.translateService.translate('notice'), NoticeComponent);
  };

  openInfoPage = () => {
    this.create(this.translateService.translate('my-page'), InfoComponent, 400);
  };

  openInquiryPage = () => {
    this.create(this.translateService.translate('inquiry'), InquiryComponent);
  };

  openNotePage = () => {
    return this.create(this.translateService.translate('note'), NoteComponent);
  };

  openPointTransferPage = () => {
    this.create(this.translateService.translate('point-transfer'), PointTransferComponent);
  };

  closeAll = () => {
    this.modalService.closeAll();
  };

  openCasinoTransferPage = (casinoList: Casino[]) => {
    const casino = casinoList.find(casino => !casino.seamless);
    if (!casino) {
      return;
    }
    this.create(this.translateService.translate('casino-money-transfer'), CasinoComponent, 1000, {
      casinoId: casino.casinoId,
    });
  };
}
