<div class="game-list-wrapper">
  <div class="search-container">
    <input type="text" nz-input class="input" [(ngModel)]="searchGameName"
      placeholder="{{'game-search-placeholder'|translate}}" />
  </div>
  <div class="gamelist-container dflex-ac-js align-content-start slot_list">
    <div *ngIf="isLoading then loading else loaded"></div>
    <ng-template #loading>
      <div class="game-container">
        <span class="text">
          {{'loading'|translate}}
        </span>
      </div>
    </ng-template>
    <ng-template #loaded>
      <div *ngIf="filteredGameList.length == 0" class="game-container">
        <span class="text">
          {{'game-list-empty'|translate}}
        </span>
      </div>
    </ng-template>
    <a *ngFor="let game of filteredGameList" class="game-btn w-ba">
      <div class="g-panel dflex-ac-jc">
        <img class="g-img w-100 lazyLoad" [src]="game.thumbnail">
        <div class="g-footer dflex-ac-jc">
          <span class="name-text">
            <span class="tit">{{game.gameName}}</span>
            <span class="tit">{{game.gameName}}</span>
          </span>
        </div>
        <button type="button" class="play-btn play_slot" (click)="onClickGame(game)">
          <span class="text">
            {{'game-start'|translate}}
          </span>
          <div class="icon w-ba">
            <span class="hover-icon" nz-icon nzType="arrow-right" nzTheme="outline"></span>
            <span nz-icon nzType="caret-right" nzTheme="fill"></span>
          </div>
        </button>
      </div>
    </a>
  </div>
</div>