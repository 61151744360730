<gz-layout [maxWidth]="1200">
  <div class="info-frame">
    <div nz-row class="margin">
      <div nz-col nzSpan="24">
        <div>
          <input type="password" nz-input placeholder="{{'user-info-form-current-password'|translate}}"
            [(ngModel)]="passwd" class="input" />
        </div>
        <div>
          <input type="password" nz-input placeholder="{{'user-info-form-new-password'|translate}}"
            [(ngModel)]="newPasswd" class="input" />
        </div>
        <div>
          <input type="password" nz-input placeholder="{{'user-info-form-new-confirm-password'|translate}}"
            [(ngModel)]="reNewPasswd" class="input" />
        </div>
      </div>
    </div>
    <div class="align-center">
      <button style="margin-top: 20px;" nz-button nzType="primary" class="btn" (click)="onChangePasswd()">
        {{'edit'|translate}}
      </button>
    </div>
  </div>
</gz-layout>