import { Component, Input, OnInit } from '@angular/core';
import { Casino, CasinoProvider, GameGroup, User } from '@models';
import { GameSelectTabType } from 'src/components/tab';

import {
  CasinoService,
  ModalService,
  NavigationService,
  NoteService,
  UserService,
  TranslateService,
} from '@services';
import { CasinoGameListComponent } from '../game-list';

@Component({
  selector: 'gz-casino-provider-list',
  templateUrl: './casino-provider-list.component.html',
  styleUrls: ['./casino-provider-list.component.sass'],
})
export class CasinoProviderListComponent implements OnInit {
  @Input() casinoList: Casino[] = [];
  @Input() slotList: Casino[] = [];
  @Input() gameGroupList: GameGroup[] = [];

  tabType: GameSelectTabType = 'NONE';

  casinoProviderId?: number;
  slotProviderId?: number;
  gameGroupId?: number;

  user: User;

  constructor(
    private casinoService: CasinoService,
    private navigationService: NavigationService,
    private modalService: ModalService,
    private userService: UserService,
    private noteService: NoteService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.userService.getCachedUserInfo().then(user => {
      this.user = user;
      this.initTabType();
    });
  }

  initTabType = () => {
    if (this.casinoBetEnabled) {
      this.tabType = 'CASINO';
    } else if (this.slotBetEnabled) {
      this.tabType = 'SLOT';
    } else {
      this.tabType = 'MINI_GAME';
    }
  };

  onTabTypeChange = (tabType: GameSelectTabType) => {
    const header = document.getElementById('header');
    header.scrollIntoView({ behavior: 'smooth' });
    this.tabType = tabType;
  };

  onClickCasinoProvider = async (casino: CasinoProvider) => {
    const hasImportantNote = await this.noteService.checkImportantNote();
    if (!hasImportantNote) {
      return;
    }
    const url = await this.casinoService.getCasinoLobbyUrl(casino.casinoId, casino.providerId);
    this.casinoService.openLobbyWindow(url);
  };

  onClickSlotProvider = async (slot: CasinoProvider) => {
    const hasImportantNote = await this.noteService.checkImportantNote();
    if (!hasImportantNote) {
      return;
    }
    this.modalService.create(
      `${this.translateService.translate(slot.providerCode)}`,
      CasinoGameListComponent,
      1320,
      {
        casinoId: slot.casinoId,
        providerCode: slot.providerCode,
      },
    );
  };

  onClickMiniGame = async (gameGroup: GameGroup) => {
    const hasImportantNote = await this.noteService.checkImportantNote();
    if (!hasImportantNote) {
      return;
    }
    this.navigationService.goToGameGroupPage(gameGroup.id);
  };

  sectionClass = (tabType: GameSelectTabType) => {
    return this.tabType == tabType ? ['sc-section', 'active'] : ['sc-section'];
  };

  casinoBtnClass = (providerId: number) => {
    if (!this.casinoProviderId) {
      return ['sc-btn', 'w-ba'];
    }
    return this.casinoProviderId === providerId ? ['sc-btn', 'w-ba'] : ['sc-btn', 'w-ba', 'off'];
  };

  slotBtnClass = (providerId: number) => {
    if (!this.slotProviderId) {
      return ['sc-btn', 'w-ba'];
    }
    return this.slotProviderId === providerId ? ['sc-btn', 'w-ba'] : ['sc-btn', 'w-ba', 'off'];
  };

  gameGroupBtnClass = (gameGroupId: number) => {
    if (!this.gameGroupId) {
      return ['sc-btn', 'w-ba'];
    }
    return this.gameGroupId === gameGroupId ? ['sc-btn', 'w-ba'] : ['sc-btn', 'w-ba', 'off'];
  };

  animationDelay = (index: number) => {
    if (index >= 100) {
      return {
        animationDelay: `${index / 100}s`,
      };
    }

    if (index >= 10) {
      return {
        animationDelay: `${index / 10}s`,
      };
    }

    return {
      animationDelay: `0.${index}s`,
    };
  };

  onCasinoProviderMouseEnter = (casinoProviderId: number) => {
    this.casinoProviderId = casinoProviderId;
  };

  onCasinoProviderMouseLeave = () => {
    this.casinoProviderId = null;
  };

  onSlotProviderMouseEnter = (slotProviderId: number) => {
    this.slotProviderId = slotProviderId;
  };

  onSlotProviderMouseLeave = () => {
    this.slotProviderId = null;
  };

  onGameMouseEnter = (gameGroupId: number) => {
    this.gameGroupId = gameGroupId;
  };

  onGameMouseLeave = () => {
    this.gameGroupId = null;
  };

  getCasinoProviderImage = (provider: CasinoProvider) => {
    return `https://static.coco-135798642.com/new-casino/home/provider/casino/${provider.providerCode}/main.png`;
  };

  getCasinoProviderLogo = (provider: CasinoProvider) => {
    return `https://static.coco-135798642.com/new-casino/home/provider/casino/${provider.providerCode}/logo.png`;
  };

  getSlotProviderImage = (provider: CasinoProvider) => {
    return `https://static.coco-135798642.com/new-casino/home/provider/slot/${provider.providerCode}/main.png`;
  };

  getSlotProviderLogo = (provider: CasinoProvider) => {
    return `https://static.coco-135798642.com/new-casino/home/provider/slot/${provider.providerCode}/logo.png`;
  };

  getPowerBallLogo = (gameGroup: GameGroup) => {
    return gameGroup.thumbnail.replace('main.png', 'logo.png');
  };

  get casinoProviderList(): CasinoProvider[] {
    return this.casinoList
      .reduce((providerList, casino) => {
        return [...providerList, ...casino.providerList];
      }, [])
      .filter(provider => provider.providerType === 'CASINO');
  }

  get slotProviderList(): CasinoProvider[] {
    return this.slotList
      .reduce((providerList, slot) => {
        return [...providerList, ...slot.providerList];
      }, [])
      .filter(provider => provider.providerType === 'SLOT');
  }

  get casinoBetEnabled() {
    if (!this.user) {
      return false;
    }
    return this.user.casinoBetEnabled && this.casinoList.length > 0;
  }

  get slotBetEnabled() {
    if (!this.user) {
      return false;
    }
    return this.user.slotBetEnabled && this.slotList.length > 0;
  }

  get powerBallBetEnabled() {
    if (!this.user) {
      return false;
    }
    return this.gameGroupList.length > 0;
  }
}
