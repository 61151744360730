<gz-layout [maxWidth]="1200">
  <div class="refund-wrapper">
    <div class="refund-background">
      <div class="refund-frame refund-header-txt">
        {{'refund-header'|translate}}
      </div>
      <div class="refund-notice-wrapper refund-frame">
        <pre class="description" style="margin-bottom: 0px;">{{notice}}</pre>
      </div>
      <div class="refund-frame refund-header-txt">
        {{'refund-request'|translate}}
      </div>
      <div class="refund-notice-wrapper refund-frame"
        style="display: flex; flex-direction: column; align-items: center;">
        <div class="button-wrapper">
          <nz-input-number [(ngModel)]="refundMoney" [nzMin]="0" [nzStep]="10000" class="refund-input"
            nzPlaceHolder="{{'refund-money-amount'|translate}}" [nzFormatter]="formatComma" [nzParser]="parseComma">
          </nz-input-number>
          <button nz-button nzType="primary" nzDanger [disabled]="disabled" [nzLoading]="refundBtnLoading"
            (click)="onRefundBtnClick()">
            {{'refund-request'|translate}}
          </button>
        </div>
        <div style="margin-top: 10px;">
          {{'refund-account'|translate}} : {{account}}
        </div>
        <div class="button-wrapper" style="margin-top: 10px;">
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(10000)">
            1{{'ten-thousands'|translate}}
          </button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(50000)">
            5{{'ten-thousands'|translate}}
          </button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(100000)">
            10{{'ten-thousands'|translate}}
          </button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(500000)">
            50{{'ten-thousands'|translate}}
          </button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(1000000)">
            1{{'million'|translate}}
          </button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(5000000)">
            5{{'million'|translate}}
          </button>
          <button nz-button class="refund-btn" nzType="default" (click)="addRefundMoney(10000000)">
            1{{'ten-million'|translate}}
          </button>
          <button nz-button class="refund-btn" nzType="primary" (click)="refundMoney = 0">
            {{'reset'|translate}}
          </button>
        </div>
      </div>
      <div class="refund-frame refund-header-txt" style="display: flex;">
        <div style="flex: 1; text-align: left;">
          {{'refund-log'|translate}}
        </div>
        <div style="flex: 1; text-align: right;">
          <button nz-button nzType="primary" [nzLoading]="refundBtnLoading" nzDanger (click)="deleteWholeRefund()">
            {{'delete-all'|translate}}
          </button>
        </div>
      </div>
      <div class="refund-frame">
        <nz-table nzShowSizeChanger [nzData]="refundLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
          [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount"
          (nzQueryParams)="onQueryParamsChange($event)" nzBordered nzOutBordered>
          <thead>
            <tr>
              <th>
                {{'money-amount'|translate}}
              </th>
              <th>
                {{'state'|translate}}
              </th>
              <th>
                {{'request-date'|translate}}
              </th>
              <th>
                {{'process-date'|translate}}
              </th>
              <th>
                {{'delete'|translate}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of refundLists">
              <td nzBreakWord>
                {{ formatComma(data.amount) }}{{'won'|translate}}
              </td>
              <td nzBreakWord>
                <div [ngClass]="moneyService.moneyStateToClass(data.state)">
                  {{ moneyService.moneyStateToStr(data.state) }}
                </div>
              </td>
              <td nzBreakWord>
                {{ commonService.formatDateTime(data.requestDate) }}
              </td>
              <td nzBreakWord>
                {{ commonService.formatDateTime(data.processDate) }}
              </td>
              <td nzBreakWord>
                <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="{{'delete-confirm'|translate}}"
                  nzOkText="{{'confirm'|translate}}" nzCancelText="{{'cancel'|translate}}"
                  (nzOnConfirm)="deleteRow(data.id)" nzDanger>
                  {{'delete'|translate}}
                </a>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</gz-layout>
<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" (nzOnCancel)="handleCancel()"
  nzOkText="{{'confirm'|translate}}" nzCancelText="{{'cancel'|translate}}" (nzOnOk)="handleOk()">

  <ng-template #modalTitle>
    {{'refund-password'|translate}}
  </ng-template>

  <ng-template #modalContent>
    <input type="password" nz-input placeholder="{{'refund-password'|translate}}" [(ngModel)]="accountPasswd" />
  </ng-template>
</nz-modal>