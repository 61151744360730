<div class="deposit-container">
  <div class="deposit">
    {{'login-id'|translate}} : {{user.username}}
  </div>
  <div class="deposit">
    <span>{{'user-money'|translate}} : {{commonService.formatNumber(user.userMoney)}} {{'won'|translate}}</span>
    <gz-clip-board [value]="user.userMoney"></gz-clip-board>
  </div>
  <div class="deposit">
    {{'deposit-target'|translate}} : {{depositUser.username}}
  </div>
  <div class="deposit">
    <span>{{'deposit-target-money'|translate}} : {{commonService.formatNumber(depositUser.userMoney)}}
      {{'won'|translate}}</span>
  </div>
  <div class="deposit button-wrapper">
    <button nz-button nzType="default" (click)="chargeAll()">
      {{'whole-money'|translate}}
    </button>
    <button nz-button nzType="primary" (click)="addChargeMoney(10000)">
      1{{'ten-thousands'|translate}}
    </button>
    <button nz-button nzType="primary" (click)="addChargeMoney(50000)">
      5{{'ten-thousands'|translate}}
    </button>
    <button nz-button nzType="primary" (click)="addChargeMoney(100000)">
      10{{'ten-thousands'|translate}}
    </button>
    <button nz-button nzType="primary" (click)="addChargeMoney(500000)">
      50{{'ten-thousands'|translate}}
    </button>
    <button nz-button nzType="primary" (click)="addChargeMoney(1000000)">
      1{{'million'|translate}}
    </button>
    <button nz-button nzType="primary" (click)="addChargeMoney(5000000)">
      5{{'million'|translate}}
    </button>
    <button nz-button nzType="primary" (click)="addChargeMoney(10000000)">
      1{{'ten-million'|translate}}
    </button>
    <button nz-button nzType="primary" (click)="money = 0">
      {{'reset'|translate}}
    </button>
  </div>
  <div class="input">
    <nz-input-number [(ngModel)]="money" [nzMin]="0" [nzStep]="10000" class="input"
      nzPlaceHolder="{{'deposit-money'|translate}}" [nzFormatter]="formatComma" [nzParser]="parseComma">
    </nz-input-number>
  </div>
  <button class="btn" nz-button nzType="primary" (click)="deposit()" [nzLoading]="loading">
    {{'deposit'|translate}}
  </button>
</div>