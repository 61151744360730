import { Component, Input } from '@angular/core';

import { User } from '@models';
import { CommonService, ModalService, UserService, TranslateService } from '@services';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'gz-partner-withdraw',
  templateUrl: './partner-withdraw.component.html',
  styleUrls: ['./partner-withdraw.component.sass'],
})
export class PartnerWithdrawComponent {
  @Input() user = new User();
  @Input() withdrawUser = new User();

  money = 0;
  loading = false;

  constructor(
    public commonService: CommonService,
    private userService: UserService,
    private modalService: ModalService,
    private modal: NzModalRef,
    private translateService: TranslateService,
  ) {}

  withdrawAll = () => {
    this.money = Math.floor(this.withdrawUser.userMoney);
  };

  addRefundMoney = (money: number) => {
    let totalMoney = this.money + Math.floor(money || 0);

    if (totalMoney > this.withdrawUser.userMoney) {
      totalMoney = Math.floor(this.withdrawUser.userMoney);
      this.modalService.error(
        this.translateService.translate('error'),
        this.translateService.translate('partner-withdraw-validate-max-money', { totalMoney }),
        true,
      );
    }

    this.money = totalMoney;
  };

  withdraw = async () => {
    if (this.money <= 0) {
      this.modalService.error(
        this.translateService.translate('error'),
        this.translateService.translate('partner-withdraw-validate-zero'),
        true,
      );
      return;
    }
    if (this.money > this.withdrawUser.userMoney) {
      this.modalService.error(
        this.translateService.translate('error'),
        this.translateService.translate('partner-withdraw-validate'),
        true,
      );
      return;
    }
    this.loading = true;
    try {
      await this.modalService.partnerConfirm(
        this.translateService.translate('children-money-withdraw'),
        `${this.commonService.formatNumber(this.money)}${this.translateService.translate(
          'partner-withdraw-ask',
        )}`,
        this.translateService.translate('children-money-withdraw'),
      );
    } catch (ex) {
      this.loading = false;
      return;
    }
    try {
      await this.userService.withdrawMoney(this.money, this.withdrawUser.userId);
      this.close();
      this.money = 0;
    } catch (ex) {
      this.modalService.error(
        this.translateService.translate('error'),
        `${this.translateService.translate(
          'children-money-withdraw',
        )} ${this.translateService.translate('error')}`,
        true,
      );
    }
    this.loading = false;
  };

  close = () => {
    this.modal.close();
  };

  formatComma = (value: number) => this.commonService.formatNumber(value);
  parseComma = (value: string) => this.commonService.formatToNumber(value);
}
