import { Component, Input } from '@angular/core';
import { User } from '@models';
import { CommonService, ModalService, UserService, TranslateService } from '@services';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'gz-partner-deposit',
  templateUrl: './partner-deposit.component.html',
  styleUrls: ['./partner-deposit.component.sass'],
})
export class PartnerDepositComponent {
  @Input() user = new User();
  @Input() depositUser = new User();

  money = 0;
  loading = false;

  constructor(
    public commonService: CommonService,
    private userService: UserService,
    private modalService: ModalService,
    private modal: NzModalRef,
    private translateService: TranslateService,
  ) {}

  chargeAll = () => {
    this.money = Math.floor(this.user.userMoney);
  };

  addChargeMoney = (money: number) => {
    let totalMoney = this.money + Math.floor(money || 0);

    if (totalMoney > this.user.userMoney) {
      totalMoney = Math.floor(this.user.userMoney);
      this.modalService.error(
        this.translateService.translate('error'),
        this.translateService.translate('partner-deposit-validate-max-money', { totalMoney }),
        true,
      );
    }

    this.money = totalMoney;
  };

  deposit = async () => {
    if (this.money <= 0) {
      this.modalService.error(
        this.translateService.translate('error'),
        this.translateService.translate('partner-deposit-validate-zero'),
        true,
      );
      return;
    }
    if (this.money > this.user.userMoney) {
      this.modalService.error(
        this.translateService.translate('error'),
        this.translateService.translate('partner-deposit-validate'),
        true,
      );
      return;
    }
    this.loading = true;
    try {
      await this.modalService.partnerConfirm(
        this.translateService.translate('children-money-deposit'),
        `${this.commonService.formatNumber(this.money)}${this.translateService.translate(
          'partner-deposit-ask',
        )}`,
        this.translateService.translate('children-money-deposit'),
      );
    } catch (ex) {
      this.loading = false;
      return;
    }
    try {
      await this.userService.depositMoney(this.money, this.depositUser.userId);
      this.close();
      this.money = 0;
    } catch (ex) {
      this.modalService.error(
        this.translateService.translate('error'),
        `${this.translateService.translate(
          'children-money-deposit',
        )} ${this.translateService.translate('error')}`,
        true,
      );
    }
    this.loading = false;
  };

  close = () => {
    this.modal.close();
  };

  formatComma = (value: number) => this.commonService.formatNumber(value);
  parseComma = (value: string) => this.commonService.formatToNumber(value);
}
