import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService, PartnerGuardService, UserGuardService } from '@services';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService, UserGuardService],
    loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'gameGroup',
    canActivate: [AuthGuardService, UserGuardService],
    loadChildren: () =>
      import('../pages/power-ball/power-ball.module').then(m => m.PowerBallModule),
  },
  {
    path: 'partner',
    canActivate: [AuthGuardService, PartnerGuardService],
    loadChildren: () => import('../pages/partner/partner.module').then(m => m.PartnerModule),
  },
  {
    path: 'sign-in',
    loadChildren: () => import('../pages/sign-in/sign-in.module').then(m => m.SignInModule),
  },
  {
    path: 'sign-up',
    loadChildren: () => import('../pages/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
