<gz-layout [maxWidth]="1200">
  <div class="note-frame align-right">
    <button nz-button nzType="primary" style="border-radius: 5px;" [nzLoading]="buttonLoading" (click)="readAllNote()">
      {{'read-all'|translate}}
    </button>
    <button nz-button nzType="primary" style="border-radius: 5px; margin-left: 20px;" [nzLoading]="buttonLoading"
      (click)="deleteAllNote()" nzDanger>
      {{'delete-all'|translate}}
    </button>
  </div>
  <div class="note-frame">
    <nz-table nzShowSizeChanger [nzData]="noteLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
      [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount" (nzQueryParams)="onQueryParamsChange($event)"
      nzBordered nzOutBordered>
      <thead>
        <tr>
          <th>
            {{'title'|translate}}
          </th>
          <th>
            {{'important'|translate}}
          </th>
          <th>
            {{'date'|translate}}
          </th>
          <th>
            {{'read-yn'|translate}}
          </th>
          <th>
            {{'watch'|translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of noteLists" [ngClass]="noteClass(data.type)">
          <td nzBreakWord>
            <span class="content">{{data.title}}</span>
          </td>
          <td nzBreakWord>
            <span class="content">{{noteService.noteTypeToStr(data.type)}}</span>
          </td>
          <td nzBreakWord>
            <span class="content">{{commonService.formatDateTime(data.createDate)}}</span>
          </td>
          <td nzBreakWord>
            <span class="content">{{noteService.readToStr(data.read)}}</span>
          </td>
          <td nzBreakWord>
            <a nz-button nzType="link" (click)="detailNote(data.id)">
              {{'watch'|translate}}
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</gz-layout>