import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { RxStompService } from '@stomp/ng2-stompjs';

import { PipeModule, ServiceModule } from '@services';
import {
  SharedModule,
  NoticeComponent,
  DetailNoticeComponent,
  PopupNoticeComponent,
  PopupNoticeListComponent,
  NoticeListComponent,
} from '@components';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    ServiceModule,
    PipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    AppComponent,
    NoticeComponent,
    NoticeListComponent,
    DetailNoticeComponent,
    PopupNoticeComponent,
    PopupNoticeListComponent,
    NoticeListComponent,
  ],
  providers: [RxStompService],
  bootstrap: [AppComponent],
})
export class AppModule {}
