import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Inquiry } from '@models';
import {
  CommonService,
  ContentService,
  InquiryService,
  ModalService,
  TranslateService,
} from '@services';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { CreateInquiryComponent } from './create';
import { DetailInquiryComponent } from './detail';

@Component({
  selector: 'gz-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.sass'],
})
export class InquiryComponent implements OnInit, OnDestroy {
  destroyed = new Subject<any>();

  loading = false;

  pageIdx = 1;
  totalCount = 0;
  pageSize = 20;

  inquiryLists: Inquiry[] = [];

  subs: Subscription[] = [];

  constructor(
    private router: Router,
    public commonService: CommonService,
    private contentService: ContentService,
    public inquiryService: InquiryService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loadInquiryList();
    this.subs.push(
      this.contentService.inquiryChange$.subscribe(() => {
        this.loadInquiryList();
      }),
    );
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        debounceTime(300),
        takeUntil(this.destroyed),
      )
      .subscribe(async () => {
        this.loadInquiryList();
      });
  }

  createInquiry = () => {
    const modal = this.modalService.create(
      this.translateService.translate('create-inquiry'),
      CreateInquiryComponent,
      700,
    );
    modal.afterClose.subscribe(() => {
      this.loadInquiryList();
    });
  };

  detailInquiry = (inquiryId: number) => {
    this.modalService.create(
      this.translateService.translate('inquiry'),
      DetailInquiryComponent,
      700,
      {
        inquiryId,
      },
    );
  };

  loadInquiryList = async () => {
    this.loading = true;
    try {
      this.totalCount = await this.inquiryService.getInquiryListCount(
        this.pageIdx - 1,
        this.pageSize,
      );
      this.inquiryLists = await this.inquiryService.getInquiryList(this.pageIdx - 1, this.pageSize);
    } catch (ex) {}
    this.loading = false;
  };

  deleteRow = async (inquiryId: number) => {
    try {
      await this.inquiryService.deleteInquiry(inquiryId);
      this.loadInquiryList();
    } catch (ex) {}
  };

  deleteAllInquiry = async () => {
    try {
      await this.modalService.confirm(
        this.translateService.translate('delete-all'),
        this.translateService.translate('delete-all-check'),
      );
      await this.inquiryService.deleteAllInquiry();
      this.loadInquiryList();
    } catch (ex) {}
  };

  onQueryParamsChange = async (params: NzTableQueryParams): Promise<void> => {
    const { pageSize, pageIndex } = params;
    this.pageIdx = pageIndex;
    this.pageSize = pageSize;
  };

  replyDate = (inquiry: Inquiry) => {
    if (!inquiry || !inquiry.replyInfo) {
      return;
    }
    return this.commonService.formatDateTime(inquiry.replyInfo?.createDate);
  };

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subs.map(sub => sub.unsubscribe());
  }
}
