import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Note, NoteType } from '@models';
import {
  CommonService,
  ContentService,
  ModalService,
  NoteService,
  TranslateService,
} from '@services';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { DetailNoteComponent } from './detail';

@Component({
  selector: 'gz-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.sass'],
})
export class NoteComponent implements OnInit, OnDestroy {
  destroyed = new Subject<any>();

  loading = false;

  pageIdx = 1;
  totalCount = 0;
  pageSize = 20;

  noteLists: Note[] = [];

  subs: Subscription[] = [];

  buttonLoading = false;

  constructor(
    public commonService: CommonService,
    private contentService: ContentService,
    private router: Router,
    public noteService: NoteService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.contentService.noteChange$.subscribe(() => {
        this.loadNoteList();
      }),
    );
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        debounceTime(300),
        takeUntil(this.destroyed),
      )
      .subscribe(async () => {
        this.loadNoteList();
      });
  }

  loadNoteList = async () => {
    this.loading = true;
    try {
      this.noteService.getUnreadNoteCount();
      this.totalCount = await this.noteService.getNoteListCount(this.pageIdx - 1, this.pageSize);
      this.noteLists = await this.noteService.getNoteList(this.pageIdx - 1, this.pageSize);
    } catch (ex) {}
    this.loading = false;
  };

  detailNote = (noteId: number) => {
    const modal = this.modalService.create(
      this.translateService.translate('note'),
      DetailNoteComponent,
      700,
      {
        noteId,
      },
    );
    modal.afterClose.subscribe(() => {
      this.loadNoteList();
    });
  };

  noteClass = (noteType: NoteType) => {
    return noteType === NoteType.IMPORTANT ? 'important' : '';
  };

  onQueryParamsChange = async (params: NzTableQueryParams): Promise<void> => {
    const { pageSize, pageIndex } = params;
    this.pageIdx = pageIndex;
    this.pageSize = pageSize;
    this.loadNoteList();
  };

  readAllNote = async () => {
    try {
      await this.modalService.confirm(
        this.translateService.translate('read-all'),
        this.translateService.translate('read-all-check'),
      );
      this.buttonLoading = true;
      await this.noteService.readAllNote();
      await this.loadNoteList();
    } catch (ex) {}
    this.buttonLoading = false;
  };

  deleteAllNote = async () => {
    try {
      await this.modalService.confirm(
        this.translateService.translate('delete-all'),
        this.translateService.translate('delete-all-check'),
      );
      this.buttonLoading = true;
      await this.noteService.deleteAllNote();
      await this.loadNoteList();
    } catch (ex) {}
    this.buttonLoading = false;
  };

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subs.map(sub => sub.unsubscribe());
  }
}
