import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '../user';
import { ContentService } from '../content';
import { RecordService } from '../record';

@Injectable()
export class PartnerGuardService implements CanActivate {
  constructor(
    private contentService: ContentService,
    private userService: UserService,
    private recordService: RecordService,
  ) {}

  async canActivate(): Promise<boolean> {
    try {
      const recordType = await this.recordService.getRecordType();
      if (recordType !== 'PARTNER') {
        return this.signOut();
      }
    } catch (ex) {
      return this.signOut();
    }

    const user = await this.userService.getCachedUserInfo();

    if (!user.partnerEnabled) {
      return this.signOut();
    }

    return true;
  }

  private signOut() {
    this.contentService.signOut();
    return false;
  }
}
