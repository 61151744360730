import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ContentService } from '../content';
import { RecordService } from '../record';

@Injectable()
export class UserGuardService implements CanActivate {
  constructor(private contentService: ContentService, private recordService: RecordService) {}

  async canActivate(): Promise<boolean> {
    try {
      const recordType = await this.recordService.getRecordType();
      if (recordType !== 'USER') {
        return this.signOut();
      }
      return true;
    } catch (ex) {
      return this.signOut();
    }
  }

  private signOut() {
    this.contentService.signOut();
    return false;
  }
}
