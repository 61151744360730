<gz-layout [maxWidth]="1200">
  <div class="charge-frame charge-header-txt">
    {{'charge-header'|translate}}
  </div>
  <div *ngIf="switeCoinChargeEnabled" class="charge-notice-wrapper charge-frame">
    <pre class="description">{{switeCoinChargeContent}}</pre>
    <div style="width: 100%; text-align: center;">
      <button nz-button nzType="primary" class="btn" (click)="onAccountNumberConfirm()" [nzLoading]="accountBtnLoading">
        {{'charging'|translate}}
      </button>
    </div>
  </div>
  <div *ngIf="virtualChargeEnabled" class="charge-notice-wrapper charge-frame">
    <pre class="description">{{virtualChargeContent}}</pre>
    <div style="width: 100%; text-align: center; margin-bottom: 10px;">
      <button nz-button nzType="primary" class="btn virtual-btn" (click)="onAccountNumberConfirm()"
        [nzLoading]="accountBtnLoading">
        {{'charge-coin-request'|translate}}
      </button>
    </div>
    <div style="width: 100%; text-align: center;">
      <button nz-button nzType="primary" class="btn" (click)="onClickCreateInquiry()" [nzLoading]="accountBtnLoading">
        {{'charge-account-inquiry'|translate}}
      </button>
    </div>
  </div>
  <div *ngIf="user && !switeCoinChargeEnabled && !virtualChargeEnabled" class="charge-notice-wrapper charge-frame">
    <pre class="description">{{noticeContent}}</pre>
    <div style="width: 100%; text-align: center; margin-bottom: 10px;">
      <button nz-button nzType="primary" class="btn" (click)="onAccountNumberConfirm()" [nzLoading]="accountBtnLoading">
        {{'charge-confirm-account-number'|translate}}
      </button>
    </div>
    <div style="width: 100%; text-align: center;">
      <button nz-button nzType="primary" class="btn" (click)="onClickCreateInquiry()" [nzLoading]="accountBtnLoading">
        {{'charge-account-inquiry'|translate}}
      </button>
    </div>
  </div>
  <div class="charge-frame charge-header-txt">
    {{'charge-request'|translate}}
  </div>
  <div class="charge-notice-wrapper charge-frame" style="display: flex; flex-direction: column; align-items: center;">
    <div>
      {{'account-owner'|translate}}: {{owner}}
    </div>
    <div *ngIf="bonusChargeList.length > 0" style="margin-top: 10px;" class="button-wrapper">
      <nz-radio-group [(ngModel)]="bonusChargeId">
        <label class="radio-label" *ngFor="let bonusCharge of bonusChargeList" [nzValue]="bonusCharge.id" nz-radio>
          {{bonusCharge.title}} {{bonusCharge.description}}
        </label>
      </nz-radio-group>
    </div>
    <div style="margin-top: 10px;" class="button-wrapper">
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(10000)">
        1{{'ten-thousands'|translate}}
      </button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(50000)">
        5{{'ten-thousands'|translate}}
      </button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(100000)">
        10{{'ten-thousands'|translate}}
      </button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(500000)">
        50{{'ten-thousands'|translate}}
      </button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(1000000)">
        1{{'million'|translate}}
      </button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(5000000)">
        5{{'million'|translate}}
      </button>
      <button nz-button class="charge-btn" nzType="default" (click)="addChargeMoney(10000000)">
        1{{'ten-million'|translate}}
      </button>
      <button nz-button class="charge-btn" nzType="primary" (click)="chargeMoney = 0">
        {{'reset'|translate}}
      </button>
    </div>
    <div class="button-wrapper" style="margin-top: 10px;">
      <nz-input-number [(ngModel)]="chargeMoney" [nzMin]="0" [nzStep]="10000" class="charge-input"
        nzPlaceHolder="{{'charge-money-amount'|translate}}" [nzFormatter]="formatComma" [nzParser]="parseComma">
      </nz-input-number>
      <button nz-button nzType="primary" [disabled]="disabled" [nzLoading]="chargeBtnLoading"
        (click)="onChargeBtnClick()">
        {{'charge-request'|translate}}
      </button>
    </div>
  </div>
  <div class="charge-frame charge-header-txt" style="display: flex">
    <div style="flex: 1; text-align: left;">
      {{'charge-log'|translate}}
    </div>
    <div style="flex: 1; text-align: right;">
      <button nz-button nzType="primary" [nzLoading]="chargeBtnLoading" nzDanger (click)="deleteWholeCharge()">
        {{'delete-all'|translate}}
      </button>
    </div>
  </div>
  <div class="charge-frame">
    <nz-table nzShowSizeChanger [nzData]="chargeLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
      [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount" (nzQueryParams)="onQueryParamsChange($event)"
      nzBordered nzOutBordered>
      <thead>
        <tr>
          <th>
            {{'money-amount'|translate}}
          </th>
          <th>
            {{'account-owner'|translate}}
          </th>
          <th>
            {{'state'|translate}}
          </th>
          <th>
            {{'request-date'|translate}}
          </th>
          <th>
            {{'process-date'|translate}}
          </th>
          <th>
            {{'delete'|translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of chargeLists">
          <td nzBreakWord>
            {{ formatComma(data.amount) }}{{'won'|translate}}
          </td>
          <td nzBreakWord>
            {{ data.owner }}
          </td>
          <td nzBreakWord>
            <div [ngClass]="moneyService.moneyStateToClass(data.state)">
              {{ moneyService.moneyStateToStr(data.state) }}
            </div>
          </td>
          <td nzBreakWord>
            {{ commonService.formatDateTime(data.requestDate) }}
          </td>
          <td nzBreakWord>
            {{ commonService.formatDateTime(data.processDate) }}
          </td>
          <td nzBreakWord>
            <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="{{'delete-confirm'|translate}}"
              nzOkText="{{'confirm'|translate}}" nzCancelText="{{'cancel'|translate}}"
              (nzOnConfirm)="deleteRow(data.id)" nzDanger>
              {{'delete'|translate}}
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</gz-layout>