<div *ngIf="notice" class="notice-frame">
  <div class="title">
    {{notice.title}}
  </div>
  <div [innerHTML]="notice.body | safeHtml" class="ql-editor content"></div>
  <div class="submit-btn-wrapper">
    <button class="submit-btn2" nz-button [nzType]="'danger'" (click)="close12Hour()">
      {{'don-t-watch-12hours'|translate}}
    </button>
  </div>
  <div class="submit-btn-wrapper">
    <button class="submit-btn" nz-button [nzType]="'primary'" (click)="closeNotice()">
      {{'cancel'|translate}}
    </button>
  </div>
</div>