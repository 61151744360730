import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  ContentService,
  ModalService,
  NavigationService,
  NoteService,
  TranslateService,
} from '@services';
import { NzModalRef } from 'ng-zorro-antd';

import { Subject } from 'rxjs';

@Component({
  selector: 'gz-detail-note',
  templateUrl: './detail-note.component.html',
  styleUrls: ['./detail-note.component.sass'],
})
export class DetailNoteComponent implements OnInit {
  destroyed = new Subject<any>();
  validateForm!: FormGroup;
  loading = false;
  @Input() noteId: number;

  inquiryLists = [];

  constructor(
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private contentService: ContentService,
    private noteService: NoteService,
    private modalService: ModalService,
    private modal: NzModalRef,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
      body: [null, [Validators.required]],
    });
    this.loadNoteDetail();
  }

  loadNoteDetail = async () => {
    try {
      const note = await this.noteService.getNote(this.noteId);
      const { title, body } = note;
      this.validateForm.setValue({
        title,
        body,
      });
      this.contentService.onNoteChange();
    } catch (ex) {}
  };

  goBack = () => {
    this.navigationService.goToNotePage();
  };

  deleteNote = async (event: Event) => {
    event.preventDefault();
    try {
      await this.modalService.confirm(
        this.translateService.translate('delete'),
        this.translateService.translate('delete-note-check'),
      );
      await this.noteService.deleteNote(this.noteId);
      this.modal.destroy();
    } catch (ex) {}
  };

  submitForm = () => {
    this.modal.destroy();
  };
}
