import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from 'src/services/translate';

@Injectable()
export class NavigationService {
  constructor(
    private router: Router,
    private location: Location,
    private translateService: TranslateService,
  ) {}

  goToSignInPage = (): void => {
    this.router.navigate(['sign-in']);
  };

  goToSignUpPage = (): void => {
    this.router.navigate(['sign-up']);
  };

  goToHomePage = (): void => {
    this.router.navigate(['']);
  };

  goToGameGroupPage = (gameGroupId: number): void => {
    window.open(
      `/gameGroup?id=${gameGroupId}`,
      '미니게임',
      `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,height=${
        screen.height * (2 / 3)
      },width=${screen.width * (2 / 3)}`,
    );
  };

  goToBettingLogPage = (gameGroupId: number, gameId: number): void => {
    this.router.navigate([`gameGroup/${gameGroupId}/game/${gameId}/log`]);
  };

  goToWholeBettingLogPage = (): void => {
    this.router.navigate([`betting/log`]);
  };

  goToCoinPage = (): void => {
    this.router.navigate(['gameGroup/coin']);
  };

  goToCasinoPage = (): void => {
    this.router.navigate(['gameGroup/casino']);
  };

  goToCopyPage = (): void => {
    this.router.navigate(['copy']);
  };

  goToInfoPage = (): void => {
    this.router.navigate(['info']);
  };

  goToInquiryPage = (): void => {
    this.router.navigate(['inquiry']);
  };

  goToCreateInquiryPage = (): void => {
    this.router.navigate(['inquiry/create']);
  };

  goToDetailInquiryPage = (inquiryId: number): void => {
    this.router.navigate([`inquiry/${inquiryId}`]);
  };

  goToChargePage = (): void => {
    this.router.navigate(['charge']);
  };

  goToRefundPage = (): void => {
    this.router.navigate(['refund']);
  };

  goToNotePage = (): void => {
    this.router.navigate(['note']);
  };

  goToDetailNotePage = (noteId: number): void => {
    this.router.navigate([`note/${noteId}`]);
  };

  goToNoticePage = (): void => {
    this.router.navigate(['notice']);
  };

  goToDetailNoticePage = (noticeId: number): void => {
    this.router.navigate([`notice/${noticeId}`]);
  };

  goToPartnerPage = (): void => {
    this.router.navigate(['partner'], {
      queryParams: { locale: this.translateService.locale },
    });
  };

  goToPartnerAccountPage = (): void => {
    this.router.navigate(['partner/account']);
  };

  goToPointTransferPage = (): void => {
    this.router.navigate(['point']);
  };

  goToMiniGamePage = (): void => {
    this.router.navigate([`mini-game`]);
  };

  goToSlotGamePage = (casinoId: number): void => {
    this.router.navigate([`slot-game/${casinoId}`]);
  };

  goToCasinoGamePage = (casinoId: number): void => {
    this.router.navigate([`casino-game/${casinoId}`]);
  };

  goBack = (): void => {
    this.location.back();
  };
}
