import { Injectable } from '@angular/core';

import { TranslateService as Translate } from '@ngx-translate/core';
import { NzI18nService, en_US, ko_KR, ja_JP } from 'ng-zorro-antd';

const LANGUAGE_REGEX = /en|ko|jp/;
const LOCALE_KEY = 'language';
const DEFAULT_LOCALE = 'en';

@Injectable()
export class TranslateService {
  constructor(private ngxTranslate: Translate, private i18n: NzI18nService) {}

  init(locale?: string) {
    this.changeLanguage(locale ? locale : this.locale);
  }

  changeLanguage(locale: string) {
    this.ngxTranslate.use(locale);
    localStorage.setItem(LOCALE_KEY, locale);

    if (locale === 'jp') {
      this.i18n.setLocale(ja_JP);
    } else if (locale === 'ko') {
      this.i18n.setLocale(ko_KR);
    } else {
      this.i18n.setLocale(en_US);
    }
  }

  get locale() {
    const storedLang = localStorage.getItem(LOCALE_KEY);
    if (storedLang) {
      return storedLang;
    }
    const browserLang = this.ngxTranslate.getBrowserLang();
    return browserLang?.match(LANGUAGE_REGEX) ? browserLang : DEFAULT_LOCALE;
  }

  translate(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.ngxTranslate.instant(key, interpolateParams);
  }
}
