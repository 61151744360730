import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@services';

@Component({
  selector: 'gz-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.sass'],
})
export class LanguageSelectComponent implements OnInit {
  language = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.language = this.translateService.locale;
  }

  changeLanguage = () => {
    this.translateService.changeLanguage(this.language);
  };
}
