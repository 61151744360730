import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RecordType } from '@models';

@Injectable()
export class RecordService {
  url = '/api/dmz/record';

  constructor(private http: HttpClient) {}

  getRecordType(): Promise<RecordType> {
    return this.http
      .get<RecordType>(`${this.url}/check`, { headers: { ignoreToken: 'true' } })
      .toPromise();
  }
}
