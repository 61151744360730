import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  BasePartner,
  Casino,
  GameGroup,
  NormalPartner,
  StepHeader,
  User,
  UserState,
} from '@models';
import {
  CommonService,
  ModalService,
  PartnerService,
  UserService,
  TranslateService,
} from '@services';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { UserInfoComponent } from '../user-info';
import { CreateUserComponent } from './create';
import { PartnerDepositComponent, PartnerWithdrawComponent } from '../modal';

export type PartnerKeyItemType =
  | 'NORMAL'
  | 'NUMBER'
  | 'PERCENT'
  | 'CASINO_GAME_RATE'
  | 'USER_STATE'
  | 'ACCOUNT'
  | 'LOGIN';

export type PartnerKeyItem = {
  key: string;
  key2?: string;
  title?: string;
  isNumber?: boolean;
  type: PartnerKeyItemType;
};

type UserMap = {
  [userId: number]: BasePartner;
};

type ChildrenMap = {
  [userId: number]: BasePartner[];
};

type OpenMap = {
  [userId: number]: {
    expand: boolean;
    childrenExpand?: boolean;
  };
};

type SearchType = 'LOGIN_ID' | 'USER_NAME';

@Component({
  selector: 'gz-partner-table',
  templateUrl: './partner-table.component.html',
  styleUrls: ['./partner-table.component.sass'],
})
export class PartnerTableComponent implements OnInit, OnDestroy {
  @Input() hasAuthToEdit = false;
  @Input() headerList: string[] = [];
  @Input() keyItemList: PartnerKeyItem[][] = [];
  @Input() loading = false;
  @Input() height?: number;
  @Input() user = new User();

  @Input() casinoList: Casino[] = [];
  @Input() gameGroupList: GameGroup[] = [];

  @Input() lineHeight = 50;
  @Input() textAlign: 'CENTER' | 'RIGHT' = 'RIGHT';

  @Input() dataList: BasePartner[] = [];
  @Output() dataListChange = new EventEmitter<BasePartner[]>();
  @Input() showMoneyAndPoint = false;

  @Output() reload = new EventEmitter();
  @Output() onClickCalc = new EventEmitter<any>();

  stepList: StepHeader[] = [];
  selectStepList: StepHeader[] = [];
  selectedStepLevel: number;

  searchKeyword = '';
  state = UserState;

  searchType: SearchType = 'LOGIN_ID';

  openMap: OpenMap = {};

  queries$ = new Subject<string>();
  sub: Subscription;

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  constructor(
    private partnerService: PartnerService,
    private modalService: ModalService,
    public commonService: CommonService,
    public userService: UserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscribeInput();
    this.loadStepList();
  }

  subscribeInput = () => {
    this.sub = this.queries$
      .pipe(
        map((query: string) => (query ? query.trim() : '')),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(this.handleSearchKeywordChange);
  };

  handleSearchKeywordChange = (searchKeyword: string) => {
    let dataList = this.getCloseAllList(this.dataList);

    if (!searchKeyword || searchKeyword === '') {
      this.onDataListChange(dataList);
      return;
    }

    const searchedUserList = this.foundUserListBySearchKeyword(dataList, searchKeyword);
    const filteredParentIdList = this.foundParentIdList(searchedUserList);

    const parentIdList = Array.from(new Set(filteredParentIdList));

    for (let parentId of parentIdList) {
      dataList = this.getOpenList(dataList, parentId);
    }

    this.onDataListChange(dataList);

    setTimeout(() => {
      this.scroll(dataList, searchedUserList);
    }, 100);
  };

  scroll = (dataList: BasePartner[], searchedUserList: BasePartner[]) => {
    if (searchedUserList.length < 1) {
      return;
    }
    const searchedUser = searchedUserList[0];
    const index = dataList
      .filter(elem => elem.expand)
      .findIndex(elem => elem.id === searchedUser.id);
    if (index < 0) {
      return;
    }
    this.viewPort.scrollToIndex(index);
  };

  foundParentIdList = (userList: BasePartner[]) => {
    return userList.map(user => user.parentId).filter(parentId => parentId);
  };

  foundUserListBySearchKeyword = (dataList: BasePartner[], searchKeyword: string) => {
    const closeList = dataList.filter(data => {
      if (this.searchType === 'LOGIN_ID') {
        return data.loginId.includes(searchKeyword);
      }
      return data.username.includes(searchKeyword);
    });

    const foundIndex = closeList.findIndex(data => {
      if (this.searchType === 'LOGIN_ID') {
        return data.loginId === searchKeyword;
      }
      return data.username === searchKeyword;
    });

    if (foundIndex < 0) {
      return closeList;
    }

    const found = closeList.splice(foundIndex, 1);
    return [...found, ...closeList];
  };

  loadStepList = () => {
    this.partnerService.getStepHeaderList().then(stepList => {
      this.stepList = stepList;
      this.selectedStepLevel = this.stepList[0]?.level || 1;
      this.selectStepList = [...this.stepList];
    });
  };

  onStepSelectChange = () => {
    const dataList = this.dataList.map(data => {
      this.searchKeyword = '';
      if (data.level === this.selectedStepLevel) {
        data.expand = true;
      } else {
        data.expand = false;
      }
      data.childrenExpand = false;
      return data;
    });

    this.onDataListChange(dataList);
  };

  getDataClass = (data: NormalPartner, keyItem: PartnerKeyItem) => {
    if (keyItem.type === 'NUMBER' || keyItem.type === 'PERCENT') {
      return [
        keyItem.isNumber
          ? this.commonService.getColorClass(this.getNumberData(data, keyItem))
          : this.commonService.getZeroClass(this.getNormalData(data, keyItem)),
      ];
    }

    if (keyItem.type === 'ACCOUNT') {
      return [
        keyItem.isNumber
          ? this.commonService.getColorClass(this.getAccountData(data, keyItem))
          : this.commonService.getZeroClass(this.getAccountData(data, keyItem)),
      ];
    }

    return '';
  };

  getData = (data: NormalPartner, keyItem: PartnerKeyItem) => {
    if (keyItem.type === 'NUMBER' || keyItem.type === 'PERCENT') {
      return this.getNumberData(data, keyItem);
    }
    if (keyItem.type === 'NORMAL') {
      return this.getNormalData(data, keyItem);
    }
    if (keyItem.type === 'ACCOUNT') {
      return this.getAccountData(data, keyItem);
    }

    return data[keyItem.key] || '';
  };

  getNumberData = (data: NormalPartner, keyItem: PartnerKeyItem) => {
    const keyList = keyItem.key.split('.');
    if (keyList.length == 1) {
      return data[keyList[0]] || 0;
    }
    return data[keyList[0]]?.[keyList[1]] || 0;
  };

  getNormalData = (data: NormalPartner, keyItem: PartnerKeyItem) => {
    const keyList = keyItem.key.split('.');
    if (keyList.length == 1) {
      return data[keyList[0]] || '';
    }
    return data[keyList[0]]?.[keyList[1]] || '';
  };

  getAccountData = (data: NormalPartner, keyItem: PartnerKeyItem) => {
    const keyList = keyItem.key.split('.');

    if (keyList.length === 1) {
      if (keyItem.key2) {
        return data[keyItem.key] + data[keyItem.key2] || 0;
      }
      return data[keyItem.key] || 0;
    }

    const firstKeyData = data[keyList[0]];
    if (keyItem.key2) {
      if (Array.isArray(firstKeyData)) {
        return firstKeyData[0][keyList[1]] + data[keyItem.key2] || 0;
      }
      return firstKeyData[keyList[1]] + data[keyItem.key2] || 0;
    }

    if (Array.isArray(firstKeyData)) {
      return firstKeyData[0][keyList[1]] || 0;
    }

    return firstKeyData[keyList[1]] || 0;
  };

  getAccountTitle = (keyItem: PartnerKeyItem) => {
    return keyItem.title ? this.translateService.translate(keyItem.title) : '';
  };

  onSearchKeywordChange = (searchKeyword: string) => {
    this.queries$.next(searchKeyword || '');
  };

  expandStr = (data: BasePartner) => {
    const emoji = this.isChildrenExpand(data) ? '▲' : '▼';
    return `${this.translateService.translate('children')} (${data.childrenCount}) ${emoji}`;
  };

  hasChildren = (data: BasePartner) => {
    return data.childrenCount > 0;
  };

  onClickChildrenExpand = (partner: BasePartner) => {
    const dataList = this.getExpandList(this.dataList, partner.id, partner.childrenExpand);
    this.onDataListChange(dataList);
  };

  getExpandList = (dataList: BasePartner[], parentId: number, childrenExpand: boolean) => {
    return childrenExpand
      ? this.getCloseList(dataList, parentId)
      : this.getOpenList(dataList, parentId);
  };

  // 열 떄는 자기 상위 다 열고
  getOpenList = (dataList: BasePartner[], parentId: number) => {
    const userMap = this.generateUserMap(dataList);
    const wholeParentList = this.getWholeParentList(parentId, userMap);

    const wholeParentMap = this.generateUserMap(wholeParentList);

    const childrenList = this.getChildrenList(dataList, parentId);
    const childrenMap = this.generateUserMap(childrenList);

    return dataList.map(data => {
      if (data.level < this.selectedStepLevel) {
        data.expand = false;
        data.childrenExpand = false;
      } else {
        if (data.id === parentId || wholeParentMap[data.id]) {
          data.childrenExpand = true;
          data.expand = true;
        }
        if (data.parentId && wholeParentMap[data.parentId]) {
          data.expand = true;
        }
        if (childrenMap[data.id]) {
          data.expand = true;
        }
      }
      return data;
    });
  };

  // 닫을 때는 자기 하위 다 닫고
  getCloseList = (dataList: BasePartner[], parentId: number) => {
    const childrenMap = this.generateChildrenMap(dataList);
    const closeList = this.getWholeChildrenList(dataList, parentId, childrenMap);
    const closeMap = this.generateUserMap(closeList);

    return dataList.map(data => {
      if (data.level < this.selectedStepLevel) {
        data.expand = false;
        data.childrenExpand = false;
      } else {
        if (data.id === parentId) {
          data.childrenExpand = false;
        }
        if (closeMap[data.id]) {
          data.childrenExpand = false;
          data.expand = false;
        }
      }

      return data;
    });
  };

  getCloseAllList = (dataList: BasePartner[]) => {
    return dataList.map(data => {
      if (data.level === this.selectedStepLevel) {
        data.expand = true;
      } else {
        data.expand = false;
      }
      data.childrenExpand = false;
      return data;
    });
  };

  getChildrenList = (dataList: BasePartner[], parentId: number) => {
    return dataList.filter(data => data.parentId === parentId);
  };

  getWholeParentList = (userId: number, userMap: UserMap) => {
    const user = userMap[userId];

    if (!user) {
      return [];
    }

    if (!user.parentId) {
      return [];
    }

    if (user.id === user.parentId) {
      return [];
    }

    const parent = userMap[user.parentId];

    if (!parent) {
      return [];
    }

    const result = this.getWholeParentList(parent.id, userMap);
    if (!result) {
      return [parent];
    }

    return [parent, ...result];
  };

  getWholeChildrenList = (dataList: BasePartner[], userId: number, childrenMap: ChildrenMap) => {
    return dataList.filter(data => this.isMyChildren(data.id, userId, childrenMap));
  };

  // 해당 userId가 parentId의 자식인지.
  isMyChildren = (userId: number, parentId: number, childrenMap: ChildrenMap) => {
    const childrenList = childrenMap[parentId];
    if (!childrenList) {
      return false;
    }
    const found = childrenList.find(children => children.id === userId);
    if (found) {
      return true;
    }
    return !!childrenList.find(children => this.isMyChildren(userId, children.id, childrenMap));
  };

  // 해당 userId의 부모에 parentId가 있는지
  isMyParent = (userId: number, parentId: number, userMap: UserMap) => {
    const user = userMap[userId];
    if (!user) {
      return false;
    }
    if (user.parentId === parentId) {
      return true;
    }
    return this.isMyParent(parentId, user.parentId, userMap);
  };

  generateUserMap = (dataList: BasePartner[]): UserMap => {
    return dataList.reduce((newObj, obj) => {
      newObj[obj.id] = obj;
      return newObj;
    }, {});
  };

  generateChildrenMap = (dataList: BasePartner[]): ChildrenMap => {
    return dataList.reduce((newObj, obj) => {
      if (!newObj[obj.parentId]) {
        newObj[obj.parentId] = [obj];
      } else {
        newObj[obj.parentId] = [...newObj[obj.parentId], obj];
      }
      return newObj;
    }, {});
  };

  isSearched = (keyword: string, searchType: SearchType) => {
    if (searchType !== this.searchType) {
      return;
    }
    if (!this.searchKeyword || this.searchKeyword === '') {
      return;
    }

    return keyword.includes(this.searchKeyword)
      ? {
          backgroundColor: 'yellow',
        }
      : {};
  };

  virtualTableStyle = () => {
    if (!this.height) {
      return;
    }
    return {
      height: `${this.height}px`,
    };
  };

  onDataListChange = (dataList: BasePartner[]) => {
    this.setOpenMap(dataList);
    this.dataListChange.emit(dataList);
    setTimeout(() => {
      this.viewPort.checkViewportSize();
    }, 0);
  };

  setOpenMap = (dataList: BasePartner[]) => {
    const openMap: OpenMap = {};

    dataList
      .filter(data => data.expand)
      .map(data => {
        openMap[data.id] = {
          expand: data.expand,
          childrenExpand: data.childrenExpand,
        };
      });

    this.openMap = openMap;
  };

  onClickCreateBtn = (user: BasePartner) => {
    const modal = this.modalService.createPartnerModal(
      this.translateService.translate('children-create'),
      CreateUserComponent,
      1000,
      {
        parentId: user.id,
        parentLoginId: user.loginId,
      },
    );
    modal.afterClose.subscribe(async result => {
      try {
        if (!result || !result.data) {
          return;
        }
        this.reload.emit();
      } catch (ex) {}
    });
  };

  onClickEditBtn = (user: BasePartner) => {
    this.modalService.createPartnerModal(
      this.translateService.translate('children-edit'),
      UserInfoComponent,
      1400,
      {
        partner: this.user,
        user,
        casinoList: this.casinoList,
        gameGroupList: this.gameGroupList,
      },
    );
  };

  onClickDepositMoney = (depositUser: User) => {
    const modal = this.modalService.createPartnerModal(
      this.translateService.translate('children-money-deposit'),
      PartnerDepositComponent,
      1000,
      {
        depositUser,
        user: this.user,
      },
    );
    modal.afterClose.subscribe(async () => {
      this.reload.emit();
    });
  };

  onClickWithdrawMoney = (withdrawUser: User) => {
    const modal = this.modalService.createPartnerModal(
      this.translateService.translate('children-money-withdraw'),
      PartnerWithdrawComponent,
      1000,
      {
        withdrawUser,
        user: this.user,
      },
    );
    modal.afterClose.subscribe(async () => {
      this.reload.emit();
    });
  };

  reset = () => {
    const step = this.stepList[0];
    this.selectedStepLevel = step?.level || 1;
    this.searchType = 'LOGIN_ID';
    this.searchKeyword = '';
  };

  isExpand = (data: BasePartner) => {
    return data.expand || this.openMap[data.id]?.expand || false;
  };

  isChildrenExpand = (data: BasePartner) => {
    return data.childrenExpand || this.openMap[data.id]?.childrenExpand || false;
  };

  get tdClass() {
    if (this.textAlign === 'CENTER') {
      return 'td-center';
    }
    return 'td-right';
  }

  get tableStepList() {
    if (this.selectedStepLevel === 0) {
      return this.stepList;
    }
    return this.stepList.filter(step => step.level >= this.selectedStepLevel);
  }

  get tableDataList() {
    return this.dataList.filter(data => this.isExpand(data));
  }

  get userDepositEnabled() {
    if (!this.user) {
      return false;
    }
    return this.user.userDepositEnabled;
  }

  get userWithdrawEnabled() {
    if (!this.user) {
      return false;
    }
    return this.user.userWithdrawEnabled;
  }

  getUserDepositDisabled(user: User) {
    if (user.userId === this.user.userId) {
      return true;
    }
    if (this.user.userDirectDepositEnabled) {
      return user.level > this.user.level + 1;
    }
    return user.level <= this.user.level;
  }

  getUserWithdrawDisabled(user: User) {
    if (user.userId === this.user.userId) {
      return true;
    }
    if (this.user.userDirectWithdrawEnabled) {
      return user.level > this.user.level + 1;
    }
    return user.level <= this.user.level;
  }

  get totalUserPoint(): number {
    return (
      Number(
        this.dataList
          .filter(data => data.level >= this.selectedStepLevel)
          .reduce((userPoint, data) => {
            return userPoint + data.userPoint || 0;
          }, 0)
          .toFixed(0),
      ) || 0
    );
  }

  get totalUserMoney(): number {
    return (
      Number(
        this.dataList
          .filter(data => data.level >= this.selectedStepLevel)
          .reduce((userMoney, data) => {
            return userMoney + data.userMoney || 0;
          }, 0)
          .toFixed(0),
      ) || 0
    );
  }

  ngOnDestroy(): void {
    this.sub && this.sub.unsubscribe();
  }
}
