import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from '../local-storage';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContentService } from '../content';
import { ThemeCode, Solution, Tokens, User } from '@models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@services';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private contentService: ContentService,
    private deviceService: DeviceDetectorService,
    private jwtHelperService: JwtHelperService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
  ) {}

  public cachedSolution: Solution;
  private url = `/auth/user`;
  private preAuthUrl = '/api/dmz';
  private captchaUrl = '/auth/captcha';

  signIn = async (
    loginId: string,
    passwd: string,
    captcha: string,
    captchaKey: string,
  ): Promise<void> => {
    try {
      const deviceInfo = this.deviceService.getDeviceInfo();
      const browser = deviceInfo.userAgent;
      const tokens = await this.http
        .post<Tokens>(
          `${this.url}/token`,
          { loginId, passwd, captcha, browser },
          { headers: { ignoreToken: 'true', captchaKey } },
        )
        .toPromise();
      this.localStorageService.saveAccessToken(tokens.accessToken);
      this.localStorageService.saveRefreshToken(tokens.refreshToken);
      this.contentService.signIn();
    } catch (ex) {
      this.contentService.signOut();
      throw new Error(ex);
    }
  };

  signInPartner = () => {
    return this.http
      .post<Tokens>(`${this.url}/partner/token`, {
        accessToken: this.localStorageService.accessToken,
        refreshToken: this.localStorageService.refreshToken,
      })
      .toPromise();
  };

  refresh(): Observable<string> {
    return this.http.patch<any>(`${this.url}/refresh`, {}).pipe(
      tap((token: string) => {
        this.localStorageService.saveAccessToken(token);
      }),
    );
  }

  keepAlive = () => {
    return this.http.patch<any>(`${this.url}/keepAlive`, {}).toPromise();
  };

  verifyRecommendCode = (recommendCode: string): Promise<boolean> => {
    let params = new HttpParams();
    params = params.append('recommendCode', recommendCode);
    return this.http
      .get<boolean>(`${this.preAuthUrl}/recommendCode`, {
        params,
        headers: { ignoreToken: 'true' },
      })
      .toPromise();
  };

  signUp = (user: User) => {
    return this.http
      .post(`${this.preAuthUrl}/signup`, user, { headers: { ignoreToken: 'true' } })
      .toPromise();
  };

  signOut = async () => {
    try {
      await this.http.post<string>(`${this.url}/logout`, {}).toPromise();
    } catch (ex) {}
    this.contentService.signOut();
  };

  isAuthenticated = (): boolean => {
    const token = this.localStorageService.accessToken;
    if (!token) {
      return false;
    }
    return !this.jwtHelperService.isTokenExpired(token);
  };

  get solutionId(): number {
    const token = this.localStorageService.accessToken;
    if (!token) {
      return;
    }
    const decoded: User = this.jwtHelperService.decodeToken(token);
    return decoded.solutionId;
  }

  get userId(): number {
    const token = this.localStorageService.accessToken;
    if (!token) {
      return;
    }
    const decoded: User = this.jwtHelperService.decodeToken(token);
    return decoded.id;
  }

  getCachedSolutionInfo = async (): Promise<Solution> => {
    if (this.cachedSolution) {
      return this.cachedSolution;
    }
    this.cachedSolution = await this.getSolutionInfo();
    // this.applyTheme();
    return this.cachedSolution;
  };

  getSolutionInfo = (): Promise<Solution> => {
    return this.http
      .get<Solution>(`${this.preAuthUrl}/solution`, { headers: { ignoreToken: 'true' } })
      .toPromise();
  };

  getFileUrl = (fileId: number): Promise<string> => {
    return this.http
      .get<string>(`/api/file/${fileId}/url`, { headers: { ignoreToken: 'true' } })
      .toPromise();
  };

  getCaptchaImage = () => {
    return this.http
      .get(`${this.captchaUrl}/image`, {
        headers: { ignoreToken: 'true' },
        observe: 'response',
        responseType: 'blob',
      })
      .toPromise();
  };

  applyTheme = () => {
    this.applyThemeCode(this.cachedSolution.userTheme.themeCode || 'purple');
  };

  openPartnerPage = async (partnerRecord?: string): Promise<void> => {
    const tokens = await this.signInPartner();

    window.open(
      `https://${partnerRecord}/sign-in?at=${tokens.accessToken}&rt=${tokens.refreshToken}&locale=${this.translateService.locale}`,
      '파트너',
      `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,height=${
        screen.height * (4 / 5)
      },width=${screen.width * (4 / 5)}`,
    );
  };

  applyThemeCode = (themeCode: ThemeCode) => {
    switch (themeCode) {
      case 'black':
      case 'yellow':
        document.documentElement.style.setProperty('--primary-color', '#FFE302');
        document.documentElement.style.setProperty('--primary-dark-color', '#F6BE00');
        break;
      case 'green':
        document.documentElement.style.setProperty('--primary-color', '#2FEF10');
        document.documentElement.style.setProperty('--primary-dark-color', '#154734');
        break;
      case 'navy':
        document.documentElement.style.setProperty('--primary-color', '#00CCFF');
        document.documentElement.style.setProperty('--primary-dark-color', '#4949FF');
        break;
      case 'red':
        document.documentElement.style.setProperty('--primary-color', '#F70D1A');
        document.documentElement.style.setProperty('--primary-dark-color', '#8B0000');
        break;
      case 'purple':
        document.documentElement.style.setProperty('--primary-color', '#F600FF');
        document.documentElement.style.setProperty('--primary-dark-color', '#6011C2');
        break;
      default:
        break;
    }
  };
}
