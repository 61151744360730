<gz-layout [maxWidth]="1200">
  <div class="notice-frame">
    <div style="font-size: 16px; color: rgba(0,0,0,.85);">
      {{'point-transfer-title'|translate}}
    </div>
    <div style="margin-top: 5px; color: rgba(0,0,0,.65);">
      {{'point-transfer-title2'|translate}}: {{pointMinMoney}}
    </div>
    <div style="margin-top: 5px; color: rgba(0,0,0,.65);">
      {{'point-transfer-title3'|translate}}: {{pointLimitTime}}{{'minute'|translate}}
    </div>
    <div style="margin-top: 5px; color: rgba(0,0,0,.65);">
      {{'point-transfer-title4'|translate}}: {{formatComma(userPoint)}}{{'won'|translate}}
    </div>
  </div>
  <div class="point-frame input-frame">
    <nz-input-number [(ngModel)]="pointMoney" [nzMin]="0" [nzStep]="10000" class="point-input"
      nzPlaceHolder="{{'point-transfer-money-amount'|translate}}" [nzFormatter]="formatComma" [nzParser]="parseComma">
    </nz-input-number>
    <button nz-button nzType="primary" [disabled]="disabled" [nzLoading]="pointBtnLoading" (click)="onPointBtnClick()">
      전환신청
    </button>
  </div>
  <div *ngIf="pointTransferLogEnabled" class="point-frame">
    <nz-table nzShowSizeChanger [nzData]="pointLists" [nzPageIndex]="pageIdx" [nzFrontPagination]="false"
      [nzPageSize]="pageSize" [nzLoading]="loading" [nzTotal]="totalCount" (nzQueryParams)="onQueryParamsChange($event)"
      nzBordered nzOutBordered>
      <thead>
        <tr>
          <th>
            {{'request-date'|translate}}
          </th>
          <th>
            {{'money-amount'|translate}}
          </th>
          <th>
            {{'result'|translate}}
          </th>
          <th>
            {{'state'|translate}}
          </th>
          <th>
            {{'process-date'|translate}}
          </th>
          <th>
            {{'function'|translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of pointLists">
          <td nzBreakWord>{{ commonService.formatDateTime(data.requestDate) }}</td>
          <td nzBreakWord>{{ formatComma(data.amount) }}{{'won'|translate}}</td>
          <td nzBreakWord>{{ formatComma(data.resultMoney) }}{{'won'|translate}}</td>
          <td nzBreakWord>
            <div [ngClass]="moneyService.moneyStateToClass(data.state)">
              {{ moneyService.moneyStateToStr(data.state) }}
            </div>
          </td>
          <td nzBreakWord>{{ commonService.formatDateTime(data.processDate) }}</td>
          <td nzBreakWord>
            <a nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="{{'delete-confirm'|translate}}"
              nzOkText="{{'confirm'|translate}}" nzCancelText="{{'cancel'|translate}}"
              (nzOnConfirm)="deleteRow(data.id)" nzDanger>
              {{'delete'|translate}}
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</gz-layout>