<div class="withdraw-container">
  <div class="withdraw">
    {{'login-id'|translate}} : {{user.username}}
  </div>
  <div class="withdraw">
    {{'user-money'|translate}} : {{commonService.formatNumber(user.userMoney)}} {{'won'|translate}}
  </div>
  <div class="withdraw">
    {{'withdraw-target'|translate}}: {{withdrawUser.username}}
  </div>
  <div class="withdraw">
    <span>{{'withdraw-target-money'|translate}} : {{commonService.formatNumber(withdrawUser.userMoney)}}
      {{'won'|translate}}</span>
    <gz-clip-board [value]="withdrawUser.userMoney"></gz-clip-board>
  </div>
  <div class="withdraw button-wrapper">
    <button nz-button nzType="default" (click)="withdrawAll()">
      {{'whole-money'|translate}}
    </button>
    <button nz-button nzType="danger" (click)="addRefundMoney(10000)">
      1{{'ten-thousands'|translate}}
    </button>
    <button nz-button nzType="danger" (click)="addRefundMoney(50000)">
      5{{'ten-thousands'|translate}}
    </button>
    <button nz-button nzType="danger" (click)="addRefundMoney(100000)">
      10{{'ten-thousands'|translate}}
    </button>
    <button nz-button nzType="danger" (click)="addRefundMoney(500000)">
      50{{'ten-thousands'|translate}}
    </button>
    <button nz-button nzType="danger" (click)="addRefundMoney(1000000)">
      1{{'million'|translate}}
    </button>
    <button nz-button nzType="danger" (click)="addRefundMoney(5000000)">
      5{{'million'|translate}}
    </button>
    <button nz-button nzType="danger" (click)="addRefundMoney(10000000)">
      1{{'ten-million'|translate}}
    </button>
    <button nz-button nzType="danger" (click)="money = 0">
      {{'reset'|translate}}
    </button>
  </div>
  <div class="input">
    <nz-input-number [(ngModel)]="money" [nzMin]="0" [nzStep]="10000" class="input"
      nzPlaceHolder="{{'withdraw-money'|translate}}" [nzFormatter]="formatComma" [nzParser]="parseComma">
    </nz-input-number>
  </div>
  <button class="btn" nz-button nzType="primary" nzDanger (click)="withdraw()" [nzLoading]="loading">
    {{'withdraw'|translate}}
  </button>
</div>