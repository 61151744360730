import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  BalanceType,
  ChargeType,
  CopyUser,
  MoneyState,
  PageModel,
  PointType,
  UpdateType,
  User,
  UserBalanceLog,
  UserPointLog,
  UserSearchType,
  UserState,
} from '@models';
import { CommonService } from '../common';
import { TranslateService } from 'src/services/translate';

@Injectable()
export class UserService {
  url = '/api/user';
  partnerUrl = '/api/partner/user';
  copyUrl = '/api/copy';

  cachedUser: User;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private translateService: TranslateService,
  ) {}

  getCachedUserInfo = async (): Promise<User> => {
    if (!this.cachedUser) {
      return this.getUserInfo();
    }
    return this.cachedUser;
  };

  getUserAccountInfo = async (): Promise<string> => {
    return this.http.get<string>(`${this.url}/account`).toPromise();
  };

  changePassword = async (passwd: string, newPasswd: string): Promise<User> => {
    return this.http
      .patch<User>(`${this.url}/passwd`, { passwd, newPasswd })
      .toPromise();
  };

  getUserInfo = async (): Promise<User> => {
    const user = await this.http.get<User>(`${this.url}`).toPromise();
    this.cachedUser = user;
    return user;
  };

  getBettingMoney = (): Promise<number> => {
    return this.http.get<number>(`/api/gameGroup/0/totalBetMoney`).toPromise();
  };

  isValidAccountPassword = (password: string): Promise<boolean> => {
    let params = new HttpParams();
    params = params.append('accountPasswd', password);
    return this.http
      .get<boolean>(`${this.url}/accountPasswd`, { params })
      .toPromise();
  };

  getCopyInfo = (gameGroupId: number, gameId: number): Promise<CopyUser> => {
    return this.http
      .get<CopyUser>(`${this.copyUrl}/gameGroup/${gameGroupId}/game/${gameId}`)
      .toPromise();
  };

  updateCopyInfo = (user: CopyUser, gameGroupId: number, gameId: number): Promise<CopyUser> => {
    return this.http
      .patch<CopyUser>(`${this.copyUrl}/gameGroup/${gameGroupId}/game/${gameId}`, user)
      .toPromise();
  };

  getMegaCoinUrl = (): Promise<string> => {
    return this.http.get<string>(`${this.url}/mega-coin/url`).toPromise();
  };

  getKingCoinUrl = (): Promise<string> => {
    return this.http.get<string>(`${this.url}/king-coin/url`).toPromise();
  };

  getSrCoinUrl = (): Promise<string> => {
    return this.http.get<string>(`${this.url}/sr-coin/url`).toPromise();
  };

  getVirtualCoinUrl = (): Promise<string> => {
    return this.http.get<string>(`${this.url}/virtual/url`).toPromise();
  };

  depositMoney = (amount: number, targetUserId: number) => {
    return this.http
      .post<string>(`${this.url}/money/deposit`, {
        amount,
        targetUserId,
      })
      .toPromise();
  };

  withdrawMoney = (amount: number, targetUserId: number) => {
    return this.http
      .post<string>(`${this.url}/money/withdraw`, {
        amount,
        targetUserId,
      })
      .toPromise();
  };

  getUserPointList = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    pointType: PointType | 'ALL',
    startDate?: Date,
  ) => {
    let params = this.generateChargeParams(pageIndex, pageSize, startDate, startDate, '0', '0');
    if (pointType !== 'ALL') {
      params = params.append('pointType', pointType);
    }
    return this.http.get<PageModel<UserPointLog>>(`${this.partnerUrl}/${userId}/point2`, {
      params,
    });
  };

  getUserBalanceList = (
    pageIndex: number,
    pageSize: number,
    userId: number,
    balanceType: BalanceType | 'ALL',
    startDate?: Date,
  ) => {
    let params = this.generateChargeParams(pageIndex, pageSize, startDate, startDate, '0', '0');
    if (balanceType !== 'ALL') {
      params = params.append('balanceType', balanceType);
    }
    return this.http.get<PageModel<UserBalanceLog>>(`${this.partnerUrl}/${userId}/money2`, {
      params,
    });
  };

  private generateChargeParams = (
    pageIndex: number,
    pageSize: number,
    startDate?: Date,
    endDate?: Date,
    state?: MoneyState | '0',
    chargeType?: ChargeType | '0',
    searchType?: UserSearchType | '0',
    searchKeyword?: string,
    exactly?: boolean,
  ): HttpParams => {
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', this.commonService.formatDateStr(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.commonService.formatDateStr(endDate));
    }
    if (state !== '0') {
      params = params.append('state', state);
    }
    if (chargeType !== '0') {
      params = params.append('chargeType', chargeType);
    }
    if (searchType !== '0') {
      params = params.append('searchType', searchType);
    }
    if (searchKeyword) {
      params = params.append('searchKeyword', searchKeyword);
    }
    if (exactly) {
      params = params.append('exactly', `${exactly}`);
    }
    params = params
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
    return params;
  };

  clearCacheUser = () => {
    this.cachedUser = null;
  };

  userStateToStr = (state: UserState): string => {
    switch (state) {
      case UserState.WAIT:
        return this.translateService.translate('user-state-wait');
      case UserState.REQUEST:
        return this.translateService.translate('user-state-request');
      case UserState.NORMAL:
        return this.translateService.translate('user-state-normal');
      case UserState.DISABLED:
        return this.translateService.translate('user-state-disabled');
      default:
        return this.translateService.translate('unknown');
    }
  };

  userLoginToStr = (login: boolean): string => {
    return login
      ? this.translateService.translate('connected')
      : this.translateService.translate('disconnected');
  };

  chargeType2Str = (chargeType: ChargeType, memo?: string): string => {
    switch (chargeType) {
      case ChargeType.USER:
        return this.translateService.translate('charge-type-user');
      case ChargeType.USER_TRANS:
        return this.translateService.translate('charge-type-user-trans');
      case ChargeType.ADMIN:
        return this.translateService.translate('charge-type-admin');
      case ChargeType.ADMIN_TRANS:
        return this.translateService.translate('charge-type-admin-trans');
      case ChargeType.DEPOSIT:
        return `${this.translateService.translate('charge-type-deposit')} (${memo})`;
      case ChargeType.WITHDRAW:
        return `${this.translateService.translate('charge-type-withdraw')} (${memo})`;
      case ChargeType.BONUS:
        return this.translateService.translate('charge-type-bonus');
      default:
        return this.translateService.translate('unknown');
    }
  };

  updateType2Str = (updateType: UpdateType, memo?: string): string => {
    switch (updateType) {
      case UpdateType.ADMIN:
        return this.translateService.translate('update-type-admin');
      case UpdateType.USER:
        return this.translateService.translate('update-type-user');
      case UpdateType.DEPOSIT:
        return `${this.translateService.translate('update-type-deposit')} (${memo})`;
      case UpdateType.WITHDRAW:
        return `${this.translateService.translate('update-type-withdraw')} (${memo})`;
      default:
        return this.translateService.translate('unknown');
    }
  };

  moneyState2Str = (state: MoneyState): string => {
    switch (state) {
      case MoneyState.WAIT:
        return this.translateService.translate('money-state-wait');
      case MoneyState.REQUEST:
        return this.translateService.translate('money-state-request');
      case MoneyState.CANCEL:
        return this.translateService.translate('money-state-cancel');
      case MoneyState.APPROVE:
        return this.translateService.translate('money-state-approve');
      default:
        return this.translateService.translate('unknown');
    }
  };

  pointTypeToStr = (pointType: PointType | 'ALL') => {
    switch (pointType) {
      case 'ALL':
        return this.translateService.translate('point-type-all');
      case 'BET':
        return this.translateService.translate('point-type-bet');
      case 'WIN':
        return this.translateService.translate('point-type-win');
      case 'LOSE':
        return this.translateService.translate('point-type-lose');
      case 'CANCEL':
        return this.translateService.translate('point-type-cancel');
      case 'CANCEL_TIE':
        return this.translateService.translate('point-type-cancel-tie');
      case 'TRANS':
        return this.translateService.translate('point-type-trans');
      case 'TRANS_CANCEL':
        return this.translateService.translate('point-type-trans-cancel');
      case 'PARTNER_BET':
        return this.translateService.translate('point-type-partner-bet');
      case 'PARTNER_WIN':
        return this.translateService.translate('point-type-partner-win');
      case 'ADMIN_CHARGE':
        return this.translateService.translate('point-type-admin-charge');
      case 'ADMIN_REFUND':
        return this.translateService.translate('point-type-admin-refund');
      case 'BONUS':
        return this.translateService.translate('point-type-bonus');
      default:
        return this.translateService.translate('unknown');
    }
  };

  balanceTypeToStr = (balanceType: BalanceType | 'ALL') => {
    switch (balanceType) {
      case 'ALL':
        return this.translateService.translate('balance-type-all');
      case 'BET':
        return this.translateService.translate('balance-type-bet');
      case 'WIN':
        return this.translateService.translate('balance-type-win');
      case 'CHARGE':
        return this.translateService.translate('balance-type-charge');
      case 'REFUND':
        return this.translateService.translate('balance-type-refund');
      case 'CANCEL_REFUND':
        return this.translateService.translate('balance-type-refund-cancel');
      case 'TRANS':
        return this.translateService.translate('balance-type-trans');
      case 'TRANSFER':
        return this.translateService.translate('balance-type-transfer');
      case 'PAYBACK':
        return this.translateService.translate('balance-type-payback');
      case 'CANCEL':
        return this.translateService.translate('balance-type-cancel');
      case 'EXCEED_WIN_LIMIT':
        return this.translateService.translate('balance-type-exceed-win-limit');
      case 'PARTNER_CHARGE':
        return this.translateService.translate('balance-type-partner-charge');
      case 'PARTNER_REFUND':
        return this.translateService.translate('balance-type-partner-refund');
      default:
        return this.translateService.translate('unknown');
    }
  };

  userStateClass = (userState: UserState): string[] => {
    switch (userState) {
      case UserState.NORMAL:
        return ['user-state', 'user-state-approve'];
      case UserState.REQUEST:
        return ['user-state', 'user-state-request'];
      case UserState.WAIT:
        return ['user-state', 'user-state-wait'];
      case UserState.DISABLED:
        return ['user-state', 'user-state-cancel'];
    }
  };

  userLoginClass = (login: boolean) => {
    return login ? 'login' : 'logout';
  };
}
