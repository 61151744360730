<div class="betting-table-wrapper">
  <div class="betting-table-info-row flex flex-wrap">
    <div class="filter-wrapper flex1 flex flex-wrap">
      <div class="filter-content">
        <gz-game-select [(gameId)]="gameId" [(gameGroupId)]="gameGroupId"></gz-game-select>
      </div>
      <div class="filter-content">
        <nz-input-group nzSearch>
          <nz-date-picker [(ngModel)]="startDate" [nzAllowClear]="false">
          </nz-date-picker>
          <button nz-button nzType="primary" nzSearch (click)="onSearchBtnClick()" [nzLoading]="searchBtnLoading">
            {{'search'|translate}}
          </button>
        </nz-input-group>
      </div>
    </div>
  </div>
  <div class="betting-table-content-row">
    <nz-table [nzData]="bettingLogLists" nzBordered nzOutBordered nzShowSizeChanger [nzLoading]="loading"
      [nzFrontPagination]="false" [nzPageIndex]="pageIdx" [nzPageSize]="pageSize" [nzTotal]="totalCount"
      (nzPageIndexChange)="onPageIndexChange($event)" (nzPageSizeChange)="onPageSizeChange($event)">
      <thead>
        <tr>
          <th *ngIf="!isBrief">
            {{'login-id-nickname'|translate}}
          </th>
          <th>
            {{'game'|translate}}
          </th>
          <th>
            {{'round'|translate}}
          </th>
          <th>
            {{'state'|translate}}
          </th>
          <th>
            {{'bet'|translate}}
          </th>
          <th *ngIf="!isBrief">배당</th>
          <th>
            {{'bet-money'|translate}}
          </th>
          <th>
            {{'win-money'|translate}}
          </th>
          <th *ngIf="!isBrief">
            {{'result-money'|translate}}
          </th>
          <th *ngIf="!isBrief">
            {{'ip'|translate}}
          </th>
          <th>
            {{'date'|translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of bettingLogLists">
          <td *ngIf="!isBrief" nzBreakWord>
            <div>{{data.loginId}}</div>
            <div>({{data.username}})</div>
          </td>
          <td nzBreakWord>{{data.gameName}}</td>
          <td nzBreakWord>{{data.raceTitle}}</td>
          <td nzBreakWord>
            <div [ngClass]="gameService.gameResultTypeToClass(data.state)">
              {{gameService.gameResultTypeToStr(data.state)}}
            </div>
          </td>
          <td nzBreakWord>{{data.pickName}}</td>
          <td *ngIf="!isBrief" nzBreakWord>{{data.rate}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.betMoney)}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.winMoney)}}</td>
          <td *ngIf="!isBrief" nzBreakWord>{{commonService.formatNumber(data.remainMoney)}}</td>
          <td *ngIf="!isBrief" nzBreakWord>{{data.clientIP}}</td>
          <td nzBreakWord>{{ commonService.formatDateTime(data.createDate) }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>