<gz-layout [maxWidth]="1200">
  <div class="inquiry-frame">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control class="form-control-pad">
          <span class="header-txt">
            {{'title'|translate}}
          </span>
          <nz-input-group class="flex" style="align-items: center;">
            <input type="text" nz-input formControlName="title" class="input" readonly />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control class="form-control-pad">
          <span class="header-txt">
            {{'content'|translate}}
          </span>
          <nz-input-group class="flex" style="align-items: center;">
            <textarea nz-input formControlName="body" class="input" style="height: 55px;" readonly></textarea>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <div *ngIf="hasReply then hasResponse else noResponse"></div>
      <ng-template #noResponse>
        <div class="no-reply">
          {{'reply-not-found'|translate}}
        </div>
      </ng-template>
      <ng-template #hasResponse>
        <nz-form-item>
          <nz-form-control class="form-control-pad">
            <span class="header-txt">
              {{'reply'|translate}} {{'title'|translate}}
            </span>
            <nz-input-group class="flex" style="align-items: center;">
              <input type="text" nz-input formControlName="replyTitle" class="input" readonly />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control class="form-control-pad">
            <span class="header-txt">
              {{'reply'|translate}} {{'content'|translate}}
            </span>
            <nz-input-group class="flex" style="align-items: center;">
              <textarea nz-input formControlName="replyBody" class="input" style="height: 150px;" readonly></textarea>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </ng-template>
      <div class="submit-btn-wrapper">
        <button class="submit-btn" nz-button [nzType]="'primary'">
          {{'close'|translate}}
        </button>
      </div>
    </form>
  </div>
</gz-layout>