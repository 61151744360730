<form *ngIf="formLoaded" nz-form [formGroup]="validateForm" class="user-create-wrapper" (ngSubmit)="submitForm()">
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-root-partner'|translate}}
        </span>
        <span class="info-txt">
          {{'partner-create-info'|translate}}
        </span>
        <nz-input-group>
          <input type="text" nz-input formControlName="parentLoginId" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="{{'partner-create-bank-error'|translate}}" class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-bank'|translate}}
        </span>
        <nz-input-group>
          <input *ngIf="partnerAccount" type="text" nz-input formControlName="bank" class="input" />
          <div *ngIf="!partnerAccount">
            {{'please-ask-admin'|translate}}
          </div>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="{{'partner-create-login-id-error'|translate}}" class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-login-id'|translate}}
        </span>
        <nz-input-group>
          <input type="text" nz-input formControlName="loginId" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="{{'partner-create-account-number-error'|translate}}" class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-account-number'|translate}}
        </span>
        <nz-input-group>
          <input *ngIf="partnerAccount" type="text" nz-input formControlName="account" class="input" />
          <div *ngIf="!partnerAccount">
            {{'please-ask-admin'|translate}}
          </div>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="{{'partner-create-nickname-error'|translate}}" class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-nickname'|translate}}
        </span>
        <nz-input-group>
          <input type="text" nz-input formControlName="username" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="{{'partner-create-account-owner-error'|translate}}" class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-account-owner'|translate}}
        </span>
        <nz-input-group>
          <input *ngIf="partnerAccount" type="text" nz-input formControlName="owner" class="input" />
          <div *ngIf="!partnerAccount">
            {{'please-ask-admin'|translate}}
          </div>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="{{'partner-create-password-error'|translate}}" class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-password'|translate}}
        </span>
        <nz-input-group>
          <input type="text" nz-input formControlName="passwd" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="{{'partner-create-phone-number-error'|translate}}" class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-phone-number'|translate}}
        </span>
        <nz-input-group>
          <input type="text" nz-input formControlName="phoneNumber" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="flex flex-wrap">
    <nz-form-item class="flex1">
      <nz-form-control nzErrorTip="{{'partner-create-refund-password-error'|translate}}" class="form-control-pad">
        <span class="header-txt">
          {{'partner-create-refund-password'|translate}}
        </span>
        <nz-input-group>
          <input type="text" nz-input formControlName="accountPasswd" class="input" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="flex1">
    </nz-form-item>
  </div>
  <div style="text-align: center; padding: 0px 20px;">
    <button nz-button nzBlock [nzType]="'primary'" [nzLoading]="loading">
      {{'partner-create'|translate}}
    </button>
  </div>
</form>