import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChargeData, MoneyRankData, MoneyState, RefundData, BonusCharge } from '@models';
import { TranslateService } from 'src/services/translate';

@Injectable()
export class MoneyService {
  constructor(private http: HttpClient, private translateService: TranslateService) {}

  url = '/api/user/money';
  mainUrl = '/api/main/refund';

  getRecentRefundList = (): Promise<MoneyRankData[]> => {
    return this.http.get<MoneyRankData[]>(`${this.mainUrl}/recent`).toPromise();
  };

  getRefundRankList = (): Promise<MoneyRankData[]> => {
    return this.http.get<MoneyRankData[]>(`${this.mainUrl}/rank`).toPromise();
  };

  getChargeList = (pageIndex: number, pageSize: number): Promise<ChargeData[]> => {
    const params = this.getPageParams(pageIndex, pageSize);
    return this.http
      .get<ChargeData[]>(`${this.url}/charge`, { params })
      .toPromise();
  };

  getPointList = (pageIndex: number, pageSize: number): Promise<ChargeData[]> => {
    const params = this.getPageParams(pageIndex, pageSize);
    return this.http
      .get<ChargeData[]>(`${this.url}/point`, { params })
      .toPromise();
  };

  getRefundList = (pageIndex: number, pageSize: number): Promise<RefundData[]> => {
    const params = this.getPageParams(pageIndex, pageSize);
    return this.http
      .get<RefundData[]>(`${this.url}/refund`, { params })
      .toPromise();
  };

  addCharge = (
    amount: number,
    bonusChargeAmount?: number,
    bonusType?: string,
  ): Promise<ChargeData> => {
    return this.http
      .post<ChargeData>(`${this.url}/charge`, { amount, bonusChargeAmount, bonusType })
      .toPromise();
  };

  addPoint = (amount: number): Promise<ChargeData> => {
    return this.http
      .post<ChargeData>(`${this.url}/point`, { amount })
      .toPromise();
  };

  addRefund = (amount: number): Promise<ChargeData> => {
    return this.http
      .post<ChargeData>(`${this.url}/refund`, { amount })
      .toPromise();
  };

  updateCharge = (id: number, visible: boolean): Promise<void> => {
    return this.http
      .patch<void>(`${this.url}/charge`, { id, visible })
      .toPromise();
  };

  deleteWholeCharge = () => {
    return this.http.delete<void>(`${this.url}/charge`).toPromise();
  };

  deleteWholeRefund = () => {
    return this.http.delete<void>(`${this.url}/refund`).toPromise();
  };

  updatePoint = (id: number, visible: boolean): Promise<void> => {
    return this.http
      .patch<void>(`${this.url}/point`, { id, visible })
      .toPromise();
  };

  updateRefund = (id: number, visible: boolean): Promise<RefundData> => {
    return this.http
      .patch<RefundData>(`${this.url}/refund`, { id, visible })
      .toPromise();
  };

  getChargeTotalCount = (): Promise<number> => {
    return this.http.get<number>(`${this.url}/charge/count`).toPromise();
  };

  getPointTotalCount = (): Promise<number> => {
    return this.http.get<number>(`${this.url}/point/count`).toPromise();
  };

  getRefundTotalCount = (): Promise<number> => {
    return this.http.get<number>(`${this.url}/refund/count`).toPromise();
  };

  getBonusChargeList = (): Promise<BonusCharge[]> => {
    return this.http.get<BonusCharge[]>(`${this.url}/bonus-charge`).toPromise();
  };

  moneyStateToStr = (moneyState: MoneyState) => {
    switch (moneyState) {
      case MoneyState.REQUEST:
        return this.translateService.translate('money-state-request');
      case MoneyState.WAIT:
        return this.translateService.translate('money-state-wait');
      case MoneyState.CANCEL:
        return this.translateService.translate('money-state-cancel');
      case MoneyState.APPROVE:
        return this.translateService.translate('money-state-approve');
      default:
        return this.translateService.translate('unknown');
    }
  };

  moneyStateToClass = (moneyState: MoneyState) => {
    switch (moneyState) {
      case MoneyState.REQUEST:
        return 'money-state-request';
      case MoneyState.WAIT:
        return 'money-state-wait';
      case MoneyState.CANCEL:
        return 'money-state-cancel';
      case MoneyState.APPROVE:
        return 'money-state-approve';
      default:
        return '';
    }
  };

  private getPageParams = (pageIndex: number, pageSize: number): HttpParams => {
    return new HttpParams()
      .append('pageIndex', pageIndex.toString())
      .append('pageSize', pageSize.toString());
  };
}
