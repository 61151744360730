<div class="refund-table-wrapper">
  <div class="refund-table-info-row flex flex-wrap">
    <div class="filter-wrapper flex1">
      <nz-range-picker [(ngModel)]="dateRange">
      </nz-range-picker>
      <button nz-button nzType="primary" nzSearch (click)="onSearchBtnClick()" [nzLoading]="searchBtnLoading">
        {{'search'|translate}}
      </button>
    </div>
  </div>
  <div class="refund-table-content-row">
    <nz-table [nzData]="refundLogLists" nzBordered nzOutBordered nzShowSizeChanger [nzLoading]="loading"
      [nzFrontPagination]="false" [nzPageIndex]="pageIdx" [nzPageSize]="pageSize" [nzTotal]="totalCount"
      (nzPageIndexChange)="onPageIndexChange($event)" (nzPageSizeChange)="onPageSizeChange($event)">
      <thead>
        <tr>
          <th>
            {{'login-id-nickname'|translate}}
          </th>
          <th>
            {{'type'|translate}}
          </th>
          <th>
            {{'bank-info'|translate}}
          </th>
          <th>
            {{'request-money'|translate}}
          </th>
          <th>
            {{'loan'|translate}}
          </th>
          <th>
            {{'result-money'|translate}}
          </th>
          <th>
            {{'request-date'|translate}}
          </th>
          <th>
            {{'process-date'|translate}}
          </th>
          <th>
            {{'state'|translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of refundLogLists">
          <td nzBreakWord>
            <div class="flex" style="flex-direction: column; align-items: center;">
              <div>{{data.loginId}}</div>
              <div>({{data.username}})</div>
            </div>
          </td>
          <td nzBreakWord>{{userService.updateType2Str(data.chargeType, data.memo)}}</td>
          <td nzBreakWord>{{data.owner}}/{{data.bank}}/{{data.account}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.amount)}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.loan)}}</td>
          <td nzBreakWord>{{commonService.formatNumber(data.resultMoney)}}</td>
          <td nzBreakWord>{{commonService.formatDateTime(data.requestDate)}}</td>
          <td nzBreakWord>{{commonService.formatDateTime(data.processDate)}}</td>
          <td nzBreakWord>{{userService.moneyState2Str(data.state)}}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>