<form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
  <div class="user-point-rate-power-ball-wrapper" formArrayName="gameList">
    <div *ngFor="let gameRow of games; let i=index" class="flex flex-wrap">
      <div *ngFor="let game of gameRow; let j=index" [formGroupName]="i*3+j" class="w3">
        <div class="margin">
          <span class="title-txt">{{game.gameName}}</span>
        </div>
        <nz-form-item>
          <nz-form-control nzErrorTip="{{'point-powerball-rate-edit-error'|translate}}" class="form-control-pad">
            <span class="header-txt">
              {{'bet'|translate}}
            </span>
            <nz-input-group class="flex" nzSuffix="%">
              <input type="number" [min]="0" [step]="0.1" nz-input class="input" formControlName="betPointRate" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="{{'point-powerball-rate-edit-error'|translate}}" class="form-control-pad">
            <span class="header-txt">
              {{'win'|translate}}
            </span>
            <nz-input-group class="flex" nzSuffix="%">
              <input type="number" [min]="0" [step]="0.1" nz-input class="input" formControlName="winPointRate" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>
  <div class="submit-btn-wrapper">
    <button class="submit-btn" nz-button [nzType]="'primary'" [nzLoading]="loading"
      [disabled]="!this.validateForm.valid">
      {{'edit'|translate}}
    </button>
  </div>
</form>